import React from 'react';
import { colors } from '@karnott/colors';

export const DeleteIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle data-name="Ellipse 391" cx="15" cy="15" r="15" fill={backgroundColor} />

      <g transform="translate(8 9)">
        <path
          d="M67.443,114.938l.066-1.174a1.1,1.1,0,0,1,1.1-1.1H71.4a1.1,1.1,0,0,1,1.1,1.1l.066,1.174"
          transform="translate(-63.498 -112.669)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g transform="translate(0 2.269)">
          <path
            d="M72.616,125.314H67.384a2.053,2.053,0,0,1-2.053-2.053l-1-8.323H75.669l-1,8.323A2.053,2.053,0,0,1,72.616,125.314Z"
            transform="translate(-63.498 -114.938)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="13.005"
            transform="translate(0 0)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(4.694 5.649)">
          <line
            x1="3.617"
            y1="3.617"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            y1="3.617"
            x2="3.617"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (14.005 / 13.645)} height={size} viewBox="0 0 14.005 13.645">
      <g transform="translate(0.5 0.5)">
        <path
          d="M67.443,114.938l.066-1.174a1.1,1.1,0,0,1,1.1-1.1H71.4a1.1,1.1,0,0,1,1.1,1.1l.066,1.174"
          transform="translate(-63.498 -112.669)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g transform="translate(0 2.269)">
          <path
            d="M72.616,125.314H67.384a2.053,2.053,0,0,1-2.053-2.053l-1-8.323H75.669l-1,8.323A2.053,2.053,0,0,1,72.616,125.314Z"
            transform="translate(-63.498 -114.938)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="13.005"
            transform="translate(0 0)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(4.694 5.649)">
          <line
            x1="3.617"
            y1="3.617"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            y1="3.617"
            x2="3.617"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
