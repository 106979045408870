import { PARCELS_CONSTANTS } from '@karnott/constants';
import { FarmhouseIcon, ParcelRentIcon, PlotsIcon } from '@karnott/icons';
import { useCallback, useMemo } from 'react';
import { UtilsHooks } from '../utils';
import { colors } from '@karnott/colors';

function usePlotCardArea(plotArea) {
  return useMemo(() => {
    return `${(plotArea * 0.0001).toFixed(2)} ha`;
  }, [plotArea]);
}

function usePlotFetchSessionsPayload(id, from, to) {
  return useMemo(
    () =>
      id
        ? {
            id: Number(id),
            start: from.format(),
            end: to.format(),
          }
        : null,
    [id, from, to],
  );
}

function usePlotFetchTracksPayload(id, from, to) {
  return useMemo(
    () =>
      id
        ? {
            parcelId: Number(id),
            start: from.format(),
            end: to.format(),
          }
        : null,
    [id, from, to],
  );
}

function usePlotFetchPayload(id) {
  return useMemo(() => (id ? id : null), [id]);
}

function usePlotFetchHistoryPayload(id) {
  return useMemo(() => (id ? id : null), [id]);
}

function usePlotCompleteFetch(id, fetchPlot, fetchHistory, fetchSessions, fetchTracks, from, to, onMount) {
  const fetchPayload = usePlotFetchPayload(id);
  const fetchHistoryPayload = usePlotFetchHistoryPayload(id);
  const fetchSessionsPayload = usePlotFetchSessionsPayload(id, from, to);
  const fetchTracksPayload = usePlotFetchTracksPayload(id, from, to);

  const fetchPlotCallback = useCallback(() => {
    if (!fetchPayload || !fetchPlot) return Promise.resolve(null);
    return fetchPlot(fetchPayload);
  }, [fetchPlot, fetchPayload]);

  const fetchPlotHistoryCallback = useCallback(() => {
    if (!fetchHistoryPayload || !fetchHistory) return Promise.resolve(null);
    return fetchHistory(fetchHistoryPayload);
  }, [fetchHistory, fetchHistoryPayload]);

  const fetchPlotSessionsCallback = useCallback(() => {
    if (!fetchSessionsPayload || !fetchSessions) return Promise.resolve(null);
    return fetchSessions(fetchSessionsPayload);
  }, [fetchSessions, fetchSessionsPayload]);

  const fetchPlotTracksCallback = useCallback(() => {
    if (!fetchTracksPayload || !fetchTracks) return Promise.resolve(null);
    return fetchTracks(fetchTracksPayload);
  }, [fetchTracks, fetchTracksPayload]);

  const fetchPlotDetails = useCallback(() => {
    return Promise.all([
      fetchPlotCallback(),
      fetchPlotHistoryCallback(),
      fetchPlotSessionsCallback(),
      fetchPlotTracksCallback(),
    ]);
  }, [fetchPlotCallback, fetchPlotSessionsCallback, fetchPlotTracksCallback, fetchPlotHistoryCallback]);

  const [refresh, , isLoading, error] = UtilsHooks.useAsyncCall(fetchPlotDetails, onMount);

  return [refresh, isLoading, error];
}

function usePlotIconAndColor({ cluster_id, status, category }) {
  return useMemo(() => {
    let Icon = PlotsIcon;
    let backgroundColor = colors('cyan');
    if (category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
      Icon = FarmhouseIcon;
      backgroundColor = colors('yellow');
    }
    if (status === PARCELS_CONSTANTS.STATUS.RENT_OVER || status === PARCELS_CONSTANTS.STATUS.BEING_RENT) {
      Icon = ParcelRentIcon;
      backgroundColor = colors('grey');
    }
    if (status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE) {
      backgroundColor = colors('sepia');
    }
    if (cluster_id === 0) {
      backgroundColor = colors('orange');
    }
    return [Icon, backgroundColor];
  }, [category, cluster_id, status]);
}

export const PlotHooks = {
  usePlotCardArea,
  usePlotCompleteFetch,
  usePlotFetchSessionsPayload,
  usePlotFetchTracksPayload,
  usePlotFetchPayload,
  usePlotIconAndColor,
};
