import React from 'react';
import { colors } from '@karnott/colors';

export const SnowRemovalIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(12 12)">
      <path
        d="M441.614,1200.78l3.969-2.906-.532,4.89,4.5,1.985-4.5,1.985.532,4.89-3.969-2.906-3.969,2.906.532-4.89-4.5-1.985,4.5-1.985-.532-4.89Z"
        transform="translate(-431.229 -1193.883)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <g transform="translate(7.097)">
        <line
          y2="21.732"
          transform="translate(3.288)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M445.814,1194.093l-3.288,2.452-3.288-2.452"
          transform="translate(-439.238 -1193.264)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M439.238,1217.62l3.288-2.452,3.288,2.452"
          transform="translate(-439.238 -1196.717)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <g transform="translate(0 3.174)">
        <line
          x1="19.078"
          y2="10.406"
          transform="translate(0.846 2.489)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M454.86,1202.669l-3.727-1.712.578-4.06"
          transform="translate(-434.09 -1196.897)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M430.75,1208.393l3.727,1.712-.578,4.06"
          transform="translate(-430.75 -1198.781)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <g transform="translate(0 3.174)">
        <line
          x2="19.078"
          y2="10.406"
          transform="translate(0.846 2.489)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M430.75,1202.669l3.727-1.712-.578-4.06"
          transform="translate(-430.75 -1196.897)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M454.86,1208.393l-3.727,1.712.578,4.06"
          transform="translate(-434.09 -1198.781)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
