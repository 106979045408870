import { colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { pixelSize, pixelSpacing, size, spacing } from '@karnott/theme';
import React, { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;
const Track = styled.div`
  flex: 1;
  position: relative;
  height: ${pixelSpacing('xSmall')};
  border-radius: ${spacing('xSmall') / 2}px;
  background-color: ${colors('grey', 200)};
`;

const SelectedTrack = styled.div`
  position: absolute;
  left: ${({ startDistance }) => startDistance}px;
  width: ${({ startDistance, endDistance }) => Math.abs(endDistance - startDistance)}px;
  top: 0;
  bottom: 0;
  border-radius: ${spacing('xSmall') / 2}px;
  background-color: ${colors('green')};
`;

const Handle = styled.div`
  position: absolute;
  top: ${-size('xLarge') / 2 + spacing('xSmall') / 2}px;
  left: ${({ distance }) => distance - size('xLarge') / 2}px;
  height: ${pixelSize('xLarge')};
  width: ${pixelSize('xLarge')};
  background-color: ${colors('green', 'dark')};
  border-radius: calc(${pixelSize('xLarge')} / 2);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 3px 6px rgba(0, 0, 0, 0.13);
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
`;
const Start = styled(Handle)``;
const End = styled(Handle)``;

export function Slider({ min = 0, max = 100, step = 1, value, onChange }) {
  const container = useRef(null);

  const [width] = UIHooks.useContainerSize(container);
  const isMultiple = useMemo(() => {
    return value && value.start !== undefined && value.start !== null;
  }, [value]);

  const [start, end] = useMemo(() => {
    if (!isMultiple) return [min, value.end];
    if (isMultiple) return [value.start, value.end];
  }, [value, isMultiple, min]);

  const distanceUnit = useMemo(() => {
    return width ? width / (max - min) : 1;
  }, [min, max, width]);

  const [startDistance, endDistance] = useMemo(() => {
    return [(start - min) * distanceUnit, (end - min) * distanceUnit];
  }, [min, max, width, start, end, distanceUnit]);

  const progress = useMemo(() => {
    return (value - min) / (max - min);
  }, [max, min, value]);

  const updateEndFromDelta = useCallback(
    deltas => {
      onChange && onChange({ start, end: end + deltas.dx / distanceUnit });
    },
    [distanceUnit, onChange, start, end],
  );

  const updateStartFromDelta = useCallback(
    deltas => {
      onChange && onChange({ start: start + deltas.dx / distanceUnit, end });
    },
    [distanceUnit, onChange, start, end],
  );

  const [endRef] = UIHooks.useDraggableContainer(updateEndFromDelta);
  const [startRef] = UIHooks.useDraggableContainer(updateStartFromDelta);
  return (
    <Container progress={progress}>
      <Track ref={container}>
        <SelectedTrack startDistance={startDistance} endDistance={endDistance} />
        <Start ref={startRef} hide={!isMultiple} distance={startDistance} />
        <End ref={endRef} distance={endDistance} />
      </Track>
    </Container>
  );
}
