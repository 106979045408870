import React from 'react';
import { colors } from '@karnott/colors';

export const MarkerIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(10 9)">
        <path
          d="M320.3,19.583c0,2.763-4.823,7.629-4.823,7.629s-4.823-4.865-4.823-7.629a4.826,4.826,0,1,1,9.646,0Z"
          transform="translate(-310.649 -14.578)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="2.263"
          cy="2.263"
          rx="2.263"
          ry="2.263"
          transform="translate(2.56 2.568)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (10.646 / 13.634)} height={size} viewBox="0 0 10.646 13.634">
      <g transform="translate(0.5 0.5)">
        <path
          d="M320.3,19.583c0,2.763-4.823,7.629-4.823,7.629s-4.823-4.865-4.823-7.629a4.826,4.826,0,1,1,9.646,0Z"
          transform="translate(-310.649 -14.578)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="2.263"
          cy="2.263"
          rx="2.263"
          ry="2.263"
          transform="translate(2.56 2.568)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
