import React from 'react';
import { colors } from '@karnott/colors';

export const SeederIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(-826.5 -852.878)">
      <rect
        width="2.856"
        height="3.633"
        rx="0.673"
        transform="translate(834.356 883.682) rotate(-180)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <rect
        width="2.856"
        height="3.633"
        rx="0.673"
        transform="translate(865.5 883.682) rotate(-180)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x1="32.428"
        transform="translate(832.171 880.048)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x1="15.916"
        transform="translate(840.53 875.943)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M855.479,867.04l-.586-3.473a.691.691,0,0,0-.689-.689H842.168a.691.691,0,0,0-.689.689l-.586,3.473Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M850.888,871.18H854.2a.691.691,0,0,0,.689-.689l.586-3.473"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M840.893,867.018l.586,3.473a.691.691,0,0,0,.689.689h3.739"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M850.888,871.18v2h-4.8v-2"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        y2="7.738"
        transform="translate(848.33 875.943)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <g>
        <path
          d="M843.7,875.943l-3.165,4.1v3.633"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x1="8.359"
          y2="4.105"
          transform="translate(832.171 875.943)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x1="2.392"
          y2="2.766"
          transform="translate(843.695 873.177)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <g>
        <path
          d="M853.262,875.93l2.827,4.118v3.633"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="8.359"
          y2="4.105"
          transform="translate(856.446 875.943)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="2.092"
          y2="2.622"
          transform="translate(850.888 873.177)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
