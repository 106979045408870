import { createSelector } from 'reselect';
import AbstractSelectors from '../selectors';

export class Selectors extends AbstractSelectors {
  constructor({ accessorChain = [], serializeValue }) {
    super({ accessorChain });
    this._addBaseSelectors([
      {
        name: 'getValue',
        selector: createSelector([this.getState], state => state?.value),
      },
      {
        name: 'getSerializedValue',
        selector: createSelector([this.getState], state =>
          serializeValue ? serializeValue(state?.value) : state?.value,
        ),
      },
      {
        name: 'loading',
        selector: createSelector([this.getState], state => state?.loading),
      },
    ]);
  }
}
