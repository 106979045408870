import AuthenticationManager from '../authentication/manager';

export default class AbstractApi {
  constructor({ root, base, authenticated }) {
    this.root = root;
    this.base = base;
    this.authenticated = authenticated;
  }

  fetchAPI(path, params, query) {
    return this.fetch(this.base + this.root + path, params, query);
  }

  fetch(url, params, query = {}) {
    return new Promise((resolve, reject) => {
      if (this.authenticated) {
        const signedHeaders = AuthenticationManager.getInstance().getAuthenticationHeaders();
        params = AbstractApi.inflateParamsWithHeaderAuth(params, signedHeaders);
      }
      fetch(`${url}?${AbstractApi.toQueryString(query)}`, params).then(response => {
        const call = response.status < 400 ? resolve : reject;
        try {
          response.json().then(call);
        } catch (e) {
          call(response);
        }
      });
    });
  }

  static toQueryString(obj) {
    if (!obj) {
      return '';
    }
    var parts = [];
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        parts.push(encodeURIComponent(i) + '=' + encodeURIComponent(obj[i]));
      }
    }
    return parts.join('&');
  }

  static arrayToQueryString(array = []) {
    if (!array) {
      return '';
    }
    var parts = [];
    array.map(({ key, value }, index) => {
      const keyIsMultiple = !!array.find(({ key: k }, i) => i !== index && k === key);
      parts.push(encodeURIComponent(key) + `${keyIsMultiple ? '[]' : ''}=` + encodeURIComponent(value));
    });
    return parts.join('&');
  }

  static isContentTypeJSON(response) {
    return (
      response.headers &&
      response.headers.map &&
      response.headers.map['content-type'] &&
      response.headers.map['content-type'].length &&
      response.headers.map['content-type'] &&
      response.headers.map['content-type'].includes('application/json')
    );
  }

  static inflateParamsWithHeaderAuth(params = {}, auth = {}) {
    return { ...params, headers: { ...params.headers, ...auth } };
  }
}

export class AbstractListApi extends AbstractApi {
  constructor({ listPath, base, root, authenticated }) {
    super({ base, root, authenticated });
    this.listPath = listPath;
  }

  fetchList(params = {}, query = {}) {
    return this.fetchAPI(this.listPath, { method: 'GET', 'content-type': 'application/json', ...params }, query);
  }
}
