import React from 'react';
import { colors } from '@karnott/colors';

export const CropIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(10 7)">
        <path
          d="M172.614,1288.257a4.352,4.352,0,0,0-2.826-1.068.764.764,0,0,0-.8.8,4.064,4.064,0,0,0,1.082,2.8,4.355,4.355,0,0,0,2.826,1.068h.032a.766.766,0,0,0,.766-.8A4.068,4.068,0,0,0,172.614,1288.257Z"
          transform="translate(-168.989 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M172.614,1284.077a4.357,4.357,0,0,0-2.826-1.069.764.764,0,0,0-.8.795,4.067,4.067,0,0,0,1.082,2.8,4.354,4.354,0,0,0,2.826,1.068h.032a.764.764,0,0,0,.766-.795A4.068,4.068,0,0,0,172.614,1284.077Z"
          transform="translate(-168.989 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M177.6,1287.189a4.35,4.35,0,0,0-2.826,1.068,4.064,4.064,0,0,0-1.082,2.8.764.764,0,0,0,.765.8h.033a4.352,4.352,0,0,0,2.826-1.068,4.068,4.068,0,0,0,1.082-2.8.764.764,0,0,0-.8-.8Z"
          transform="translate(-168.989 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M177.6,1283.009a4.348,4.348,0,0,0-2.826,1.068,4.063,4.063,0,0,0-1.082,2.8.764.764,0,0,0,.765.8h.033a4.354,4.354,0,0,0,2.826-1.068,4.071,4.071,0,0,0,1.082-2.8.764.764,0,0,0-.8-.794Z"
          transform="translate(-168.989 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M174.253,1284.659l.022-.024a4.349,4.349,0,0,0,1.243-2.753,4.065,4.065,0,0,0-1.215-2.746.762.762,0,0,0-.561-.247.773.773,0,0,0-.566.249,4.354,4.354,0,0,0-1.243,2.753,4.069,4.069,0,0,0,1.216,2.746.763.763,0,0,0,.561.247A.774.774,0,0,0,174.253,1284.659Z"
          transform="translate(-168.989 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y2="10.449"
          transform="translate(4.707 5.995)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (10.415 / 17.443)} height={size} viewBox="0 0 10.415 17.443">
      <g transform="translate(0.5 0.5)">
        <path
          d="M172.614,1288.257a4.352,4.352,0,0,0-2.826-1.068.764.764,0,0,0-.8.8,4.064,4.064,0,0,0,1.082,2.8,4.355,4.355,0,0,0,2.826,1.068h.032a.766.766,0,0,0,.766-.8A4.068,4.068,0,0,0,172.614,1288.257Z"
          transform="translate(-168.989 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M172.614,1284.077a4.357,4.357,0,0,0-2.826-1.069.764.764,0,0,0-.8.795,4.067,4.067,0,0,0,1.082,2.8,4.354,4.354,0,0,0,2.826,1.068h.032a.764.764,0,0,0,.766-.795A4.068,4.068,0,0,0,172.614,1284.077Z"
          transform="translate(-168.989 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M177.6,1287.189a4.35,4.35,0,0,0-2.826,1.068,4.064,4.064,0,0,0-1.082,2.8.764.764,0,0,0,.765.8h.033a4.352,4.352,0,0,0,2.826-1.068,4.068,4.068,0,0,0,1.082-2.8.764.764,0,0,0-.8-.8Z"
          transform="translate(-168.989 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M177.6,1283.009a4.348,4.348,0,0,0-2.826,1.068,4.063,4.063,0,0,0-1.082,2.8.764.764,0,0,0,.765.8h.033a4.354,4.354,0,0,0,2.826-1.068,4.071,4.071,0,0,0,1.082-2.8.764.764,0,0,0-.8-.794Z"
          transform="translate(-168.989 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M174.253,1284.659l.022-.024a4.349,4.349,0,0,0,1.243-2.753,4.065,4.065,0,0,0-1.215-2.746.762.762,0,0,0-.561-.247.773.773,0,0,0-.566.249,4.354,4.354,0,0,0-1.243,2.753,4.069,4.069,0,0,0,1.216,2.746.763.763,0,0,0,.561.247A.774.774,0,0,0,174.253,1284.659Z"
          transform="translate(-168.989 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y2="10.449"
          transform="translate(4.707 5.995)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
