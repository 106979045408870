import React from 'react';
import { colors } from '@karnott/colors';

export const AdminIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <g transform="matrix(1, -0.017, 0.017, 1, 0, 0.524)">
        <circle cx="15" cy="15" r="15" fill={backgroundColor} />
        <g transform="translate(8 9)">
          <path
            d="M18.3,71.1l2.924-4.2,2.924,4.2"
            transform="translate(-13.753 -63.873)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M15.446,75.832l.056-8.938,2.924,4.2"
            transform="translate(-13.933 -63.874)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M23.8,71.1l2.924-4.2v8.933"
            transform="translate(-13.405 -63.873)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M27.256,75.362a26.788,26.788,0,0,1-6.1.622,27.932,27.932,0,0,1-5.7-.529"
            transform="translate(-13.933 -63.339)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <ellipse
            cx="1.512"
            cy="1.512"
            rx="1.512"
            ry="1.512"
            transform="translate(5.963)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <ellipse
            cx="1.512"
            cy="1.512"
            rx="1.512"
            ry="1.512"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <ellipse
            cx="1.512"
            cy="1.512"
            rx="1.512"
            ry="1.512"
            transform="translate(11.81)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (15.835 / 13.645)} height={size} viewBox="0 0 15.835 13.645">
      <g transform="translate(0.5 0.5)">
        <path
          d="M18.3,71.1l2.924-4.2,2.924,4.2"
          transform="translate(-13.753 -63.873)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M15.446,75.832l.056-8.938,2.924,4.2"
          transform="translate(-13.933 -63.874)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M23.8,71.1l2.924-4.2v8.933"
          transform="translate(-13.405 -63.873)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M27.256,75.362a26.788,26.788,0,0,1-6.1.622,27.932,27.932,0,0,1-5.7-.529"
          transform="translate(-13.933 -63.339)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="1.512"
          cy="1.512"
          rx="1.512"
          ry="1.512"
          transform="translate(5.963)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="1.512"
          cy="1.512"
          rx="1.512"
          ry="1.512"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="1.512"
          cy="1.512"
          rx="1.512"
          ry="1.512"
          transform="translate(11.81)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
