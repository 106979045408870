import { useEffect, useMemo } from 'react';
import { ChartsHooks } from '../effects';
import * as d3 from 'd3';

function useDataSerieCircularDraw(
  svg,
  dataSerie = { name: '', data: [], meta: {} },
  descriptions = [],
  options = {},
  tooltipID,
) {
  const { radius, width, height, tapeWidth = 20 } = options;
  //DATA PROCESSING

  const data = ChartsHooks.useFlattenedDataSerie(dataSerie, descriptions);

  const generator = useMemo(() => {
    return d3
      .pie()
      .value(d => d)
      .sort((a, b) => d3.ascending(a.key, b.key));
  }, []);

  const pie = useMemo(() => {
    return generator(data);
  }, [data, generator]);

  const arc = useMemo(() => {
    return d3.arc();
  }, [radius]);

  //ANIMATION INTERPOLATIONS

  const angleInterpolation = useMemo(
    () => d3.interpolate(generator.startAngle()(), generator.endAngle()()),
    [generator],
  );
  const innerRadiusInterpolation = useMemo(() => d3.interpolate(0, radius - tapeWidth), [radius, tapeWidth]);
  const outerRadiusInterpolation = useMemo(() => d3.interpolate(0, radius), [radius]);

  //SVG Elements

  const [defs] = ChartsHooks.useSvgElement({
    type: 'defs',
    parent: svg,
  });

  const [filterUrl] = ChartsHooks.useDropShadowOnSvgDefs({ defs, id: 'arcs-drop-shadow' });

  const { attributes: serieAttributes } = useMemo(
    () => ({
      attributes: [
        { key: 'class', value: 'serie' },
        { key: 'transform', value: `translate(${width / 2},${height / 2} )` },
        { key: 'filter', value: filterUrl },
      ],
    }),
    [width, height, filterUrl],
  );

  const [serie] = ChartsHooks.useSvgElement({
    type: 'g',
    parent: svg,
    attributes: serieAttributes,
  });

  //UPDATES

  useEffect(() => {
    if (!serie) return () => {};
    serie
      .transition()
      .duration(1000)
      .tween('arcRadii', () => {
        return t => arc.innerRadius(innerRadiusInterpolation(t)).outerRadius(outerRadiusInterpolation(t));
      });
    return () => {};
  }, [serie, innerRadiusInterpolation, outerRadiusInterpolation, arc]);

  useEffect(() => {
    if (!serie || !radius || !pie) return () => {};
    const arcs = serie.selectAll('path').data(pie);
    const tooltip = d3.select(`#${tooltipID}`);

    arcs
      .enter()
      .append('path')
      .on('mousemove', function (e, d) {
        if (tooltip) {
          d3.select(this).attr('d', function (d) {
            return d3
              .arc()
              .innerRadius(radius - 14)
              .outerRadius(radius + 2)(d);
          });
          const mousePos = d3.pointer(e);
          const value = dataSerie.renderData ? dataSerie.renderData(d.value) : d.value;
          tooltip
            .style('left', `calc(50% + ${mousePos[0] + 12 + 'px'})`)
            .style('top', `calc(50% + ${mousePos[1] + 'px'})`)
            .html(value);
          tooltip.classed('hidden', false);
        }
      })
      .on('mouseout', function () {
        if (tooltip) {
          d3.select(this).attr('d', function (d) {
            return d3
              .arc()
              .innerRadius(radius - 14)
              .outerRadius(radius)(d);
          });

          tooltip.classed('hidden', true);
        }
      })
      .merge(arcs)
      .transition()
      .duration(1000)
      .attrTween('d', d => {
        let originalEnd = d.endAngle;
        return t => {
          let currentAngle = angleInterpolation(t);
          if (currentAngle < d.startAngle) {
            return '';
          }
          d.endAngle = Math.min(currentAngle, originalEnd);
          return arc(d);
        };
      })
      .attr('fill', (d, i) => descriptions[i].color)
      .attr('stroke', (d, i) => descriptions[i].color)
      .style('stroke-width', '0px')
      .style('opacity', 0.7);

    arcs.exit().remove();

    return () => {};
  }, [
    pie,
    serie,
    radius,
    descriptions,
    arc,
    angleInterpolation,
    innerRadiusInterpolation,
    outerRadiusInterpolation,
    tooltipID,
  ]);

  return [serie];
}

export const CircularChartHooks = {
  useDataSerieCircularDraw,
};
