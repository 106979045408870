import React from 'react';
import { colors } from '@karnott/colors';

export const PlowIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(7 12)">
      <circle cx="0.562" cy="0.562" r="0.562" transform="translate(25.312 15.721)" fill={color} />
      <ellipse
        cx="4.125"
        cy="4.233"
        rx="4.125"
        ry="4.233"
        transform="translate(21.75 12.05)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x2="7.923"
        y2="2.776"
        transform="translate(0 3.671)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M766.919,806.978l-2.684-3.223H751.641v-3.39h17.952v5.6"
        transform="translate(-743.718 -793.918)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M751.641,803.754h-7.923l7.923-3.39"
        transform="translate(-743.718 -793.918)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M763.287,800.365a9.572,9.572,0,0,0-5.2-6.447v6.447Z"
        transform="translate(-743.718 -793.918)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M756.846,800.365a9.572,9.572,0,0,0-5.205-6.447v6.447Z"
        transform="translate(-743.718 -793.918)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M758.083,803.754V810.2a9.572,9.572,0,0,0,5.2-6.446Z"
        transform="translate(-743.718 -793.918)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M751.641,803.754V810.2a9.572,9.572,0,0,0,5.205-6.446Z"
        transform="translate(-743.718 -793.918)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
