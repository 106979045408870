import React from 'react';
import { colors } from '@karnott/colors';

export const BeaconIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(9 9)">
        <g>
          <path
            d="M270.391,125.867h-7.676a.767.767,0,0,1-.541-.225l-1.72-1.718a.767.767,0,0,1-.225-.542v-7.675a.767.767,0,0,1,.225-.541l1.72-1.718a.762.762,0,0,1,.541-.225h7.676a.762.762,0,0,1,.541.225l1.719,1.718a.766.766,0,0,1,.225.541v7.675a.767.767,0,0,1-.225.542l-1.719,1.718A.767.767,0,0,1,270.391,125.867Z"
            transform="translate(-260.23 -113.222)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <ellipse
            cx="1.843"
            cy="1.843"
            rx="1.843"
            ry="1.843"
            transform="translate(4.48 4.479)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M268.159,123.5h-3.592a2.232,2.232,0,0,1-1.58-.655l-.111-.11a2.237,2.237,0,0,1-.655-1.581v-3.591a2.237,2.237,0,0,1,.655-1.581l.111-.111a2.235,2.235,0,0,1,1.58-.654h3.592a2.235,2.235,0,0,1,1.58.654l.111.111a2.236,2.236,0,0,1,.655,1.581v3.591a2.236,2.236,0,0,1-.655,1.581l-.111.11A2.232,2.232,0,0,1,268.159,123.5Z"
            transform="translate(-260.039 -113.031)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="1.61"
            y2="1.61"
            transform="translate(9.952 9.951)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="1.61"
            y2="1.61"
            transform="translate(1.084 1.084)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x1="1.467"
            y2="1.467"
            transform="translate(1.228 9.951)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x1="1.61"
            y2="1.61"
            transform="translate(9.952 1.084)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (13.646 / 13.645)} height={size} viewBox="0 0 13.646 13.645">
      <g transform="translate(0.5 0.5)">
        <g>
          <path
            d="M270.391,125.867h-7.676a.767.767,0,0,1-.541-.225l-1.72-1.718a.767.767,0,0,1-.225-.542v-7.675a.767.767,0,0,1,.225-.541l1.72-1.718a.762.762,0,0,1,.541-.225h7.676a.762.762,0,0,1,.541.225l1.719,1.718a.766.766,0,0,1,.225.541v7.675a.767.767,0,0,1-.225.542l-1.719,1.718A.767.767,0,0,1,270.391,125.867Z"
            transform="translate(-260.23 -113.222)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <ellipse
            cx="1.843"
            cy="1.843"
            rx="1.843"
            ry="1.843"
            transform="translate(4.48 4.479)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M268.159,123.5h-3.592a2.232,2.232,0,0,1-1.58-.655l-.111-.11a2.237,2.237,0,0,1-.655-1.581v-3.591a2.237,2.237,0,0,1,.655-1.581l.111-.111a2.235,2.235,0,0,1,1.58-.654h3.592a2.235,2.235,0,0,1,1.58.654l.111.111a2.236,2.236,0,0,1,.655,1.581v3.591a2.236,2.236,0,0,1-.655,1.581l-.111.11A2.232,2.232,0,0,1,268.159,123.5Z"
            transform="translate(-260.039 -113.031)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="1.61"
            y2="1.61"
            transform="translate(9.952 9.951)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="1.61"
            y2="1.61"
            transform="translate(1.084 1.084)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x1="1.467"
            y2="1.467"
            transform="translate(1.228 9.951)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x1="1.61"
            y2="1.61"
            transform="translate(9.952 1.084)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
