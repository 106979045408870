import { UIHooks } from '@karnott/hooks';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { colors } from '@karnott/colors';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';
import styled from 'styled-components';
import { Effects } from './effects';

const Container = styled.div`
  font-family: ${fontFamily()};
  display: flex;
  flex-direction: column;
  font-family: ${fontFamily()};
  font-size: ${pixelSize()};
  align-items: flex-start;
  color: ${colors('grey', 400)};
  position: relative;
  overflow: visible;
  box-sizing: border-box;
`;

const StyledInput = styled.input`
  border: solid 1px;
  width: 100%;
  box-sizing: border-box;
  padding: ${pixelSpacing('small')};
  padding-top: ${pixelSpacing('xSmall')};
  padding-bottom: ${pixelSpacing('xSmall')};
  border-radius: ${pixelSpacing('xSmall')};
  color: ${({ error, disabled }) => {
    if (disabled) return colors('grey', 200);
    return error ? colors('red') : colors('black');
  }};
  background-color: ${({ error }) => {
    return error ? colors('red', 300) : colors('white');
  }};
  outline: none;
  border-color: ${({ disabled, focused, valid, error }) => {
    if (error) return colors('red', 500);
    if (disabled) return colors('grey', 200);
    if (valid) return colors('green');
    if (focused) return colors('grey', 500);
    return colors('grey', 300);
  }};
  ${({ elevation = '' }) => elevation}
`;

const Label = styled.span`
  margin-bottom: ${pixelSpacing('xSmall')};
  color: ${colors('grey')};
  font-family: ${fontFamily()};
`;
const Error = styled.span`
  margin-top: ${pixelSpacing('xSmall')};
  color: ${colors('red')};
  font-size: ${pixelSize('small')};
  align-self: flex-end;
  font-family: ${fontFamily()};
`;

function Input({ disabled, valid, error, value, label, onValueChange, onBlur, onFocus }, ref) {
  const input = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      input.current?.focus();
    },
    blur: () => {
      input.current?.blur();
    },
  }));

  const [focused, focusCB, blurCB] = Effects.useInputFocus({ onFocus, onBlur });
  const elevation = UIHooks.useElevation({ elevated: focused });

  return (
    <Container>
      {label ? <Label>{label}</Label> : null}
      <StyledInput
        {...{ elevation }}
        onChange={onValueChange}
        onFocus={focusCB}
        onBlur={blurCB}
        ref={input}
        {...{ disabled, focused, valid, error, value }}
      />
      {error && typeof error === 'string' ? <Error>{error}</Error> : null}
    </Container>
  );
}

Input = forwardRef(Input);
export { Input, Effects as InputEffects };
