import { colors, rgbaColors } from '@karnott/colors';
import { pixelSize, pixelSpacing, size, spacing } from '@karnott/theme';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ChartsHooks } from '../../effects';
import { PerformanceScoreboardHooks } from '../effects';

const Heads = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
`;

const Group = styled.div`
  display: flex;
  padding-right: ${pixelSpacing()};
  align-items: center;
  justify-content: flex-start;
`;

const Name = styled.div`
  display: flex;
  margin-left: ${pixelSpacing('small')};
  font-size: ${pixelSize('small')};
`;

const Icon = styled.div`
  display: flex;
`;

export function ScoreHeads({ dataSeries }) {
  const icon = useCallback(dataSerie => {
    if (dataSerie?.meta?.Icon) {
      const Icon = dataSerie.meta.Icon;
      return <Icon size={size('xLarge')} circled backgroundColor={colors('black', 'dark')} color={colors('white')} />;
    }
    return null;
  }, []);

  return (
    <Heads>
      {dataSeries.map(serie => {
        return (
          <Group key={serie.name}>
            <Icon>{icon(serie)}</Icon>
            <Name>{serie.name}</Name>
          </Group>
        );
      })}
    </Heads>
  );
}
