import { AbstractReducer } from '../abstract';

export default class Reducer extends AbstractReducer {
  constructor({ actioner, initialState = null }) {
    super({ initialState });
    this._actioner = actioner;
  }

  reduce(state = null, action) {
    if (state === null) state = this.initialState;
    switch (action.type) {
      case this._actioner.actionTypes.SET:
        return action.payload;
      case this._actioner.actionTypes.RE_INIT:
        return this.initialState;
      default:
        return state;
    }
  }
}
