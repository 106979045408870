import React from 'react';
import { colors } from '@karnott/colors';

export const PenIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(9 9)">
        <path
          d="M213.177,72.284l7.375-7.375a.741.741,0,0,1,1.048,0l2.236,2.236a.741.741,0,0,1,0,1.048l-7.375,7.375"
          transform="translate(-211.408 -64.692)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M213.46,71.066l-1.769,5.052,5.052-1.769L215.5,73.1l-1.3-1.3Z"
          transform="translate(-211.692 -63.474)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (13.721 / 13.645)} height={size} viewBox="0 0 13.721 13.645">
      <g transform="translate(0.5 0.5)">
        <path
          d="M213.177,72.284l7.375-7.375a.741.741,0,0,1,1.048,0l2.236,2.236a.741.741,0,0,1,0,1.048l-7.375,7.375"
          transform="translate(-211.408 -64.692)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M213.46,71.066l-1.769,5.052,5.052-1.769L215.5,73.1l-1.3-1.3Z"
          transform="translate(-211.692 -63.474)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
