import React from 'react';
import { colors } from '@karnott/colors';

export const CalendarIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(8 9)">
        <path
          d="M224.188,126.035H211.647a.466.466,0,0,1-.465-.465V114.884a.466.466,0,0,1,.465-.464h12.54a.464.464,0,0,1,.465.464h0v10.685A.465.465,0,0,1,224.188,126.035Z"
          transform="translate(-211.182 -113.389)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M224.652,119.708h-13.47v-4.82a.466.466,0,0,1,.465-.465h12.54a.465.465,0,0,1,.465.465h0Z"
          transform="translate(-211.182 -113.389)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g transform="translate(3.593)">
          <g>
            <line y2="2.564" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          </g>
          <g transform="translate(6.285)">
            <line y2="2.564" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (14.47 / 13.645)} height={size} viewBox="0 0 14.47 13.645">
      <g transform="translate(0.5 0.5)">
        <path
          d="M224.188,126.035H211.647a.466.466,0,0,1-.465-.465V114.884a.466.466,0,0,1,.465-.464h12.54a.464.464,0,0,1,.465.464h0v10.685A.465.465,0,0,1,224.188,126.035Z"
          transform="translate(-211.182 -113.389)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M224.652,119.708h-13.47v-4.82a.466.466,0,0,1,.465-.465h12.54a.465.465,0,0,1,.465.465h0Z"
          transform="translate(-211.182 -113.389)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g transform="translate(3.593)">
          <g>
            <line y2="2.564" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          </g>
          <g transform="translate(6.285)">
            <line y2="2.564" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          </g>
        </g>
      </g>
    </svg>
  );
