import { createSelector } from 'reselect';
import { AbstractSelectors } from '../abstract';

export default class Selectors extends AbstractSelectors {
  constructor({ accessorChain = [] }) {
    super({ accessorChain });
    this._addBaseSelectors([
      {
        name: 'getCredentials',
        selector: createSelector([this.getState], state => state),
      },
      {
        name: 'hasAccessToken',
        selector: createSelector([this.getState], state => !!state?.access_token),
      },
    ]);
  }
}
