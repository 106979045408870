import { colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { fontFamily, pixelSize, pixelSpacing, spacing } from '@karnott/theme';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Descriptions } from '../Filters';
import { ChartsHooks } from '../effects';
import { BarChartHooks } from './effects';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  background-color: ${({ background }) => background};
  font-family: ${fontFamily()};
  font-weight: 500;
  padding: ${pixelSpacing()};
  ${({ elevation = '' }) => elevation}
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${pixelSpacing('small')};
`;

const ChartContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
`;

const Chart = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  min-height: ${spacing('xLarge') * 1.5}px;
  position: relative;
`;

export function BarChart({
  dataSeries = { descriptions: [], series: [] },
  background = colors('white'),
  renderTitle,
  withDescription,
  withTooltip,
}) {
  const chart = useRef(null);
  const { attributes: chartAttributes, styles: chartStyles } = useMemo(
    () => ({
      attributes: [{ key: 'class', value: 'bar-chart' }],
      styles: [{ key: 'background-color', value: 'transparent' }],
    }),
    [],
  );

  const seriesColors = useMemo(() => {
    return dataSeries.descriptions.map(({ color }) => color || colors('black', 'light'));
  }, [dataSeries.descriptions]);

  const [svg, width, height] = ChartsHooks.useSvg(chart, chartAttributes, chartStyles);
  const [defs] = ChartsHooks.useSvgElement({ type: 'defs', parent: svg });
  const [filterUrl] = ChartsHooks.useDropShadowOnSvgDefs({ defs, id: 'bar-chart-drop-shadow' });

  const options = useMemo(
    () => ({
      styles: [
        { key: 'fill', value: ({ descriptionIndex }, index) => seriesColors[descriptionIndex] },
        { key: 'filter', value: filterUrl },
        { key: 'overflow-y', value: 'hidden' },
      ],
    }),
    [seriesColors, filterUrl],
  );

  const flattenedDataSeries = BarChartHooks.useFlattenedDataSeries(dataSeries);
  const scales = BarChartHooks.useDataSeriesVerticalScale(chart, flattenedDataSeries, 100);
  const tooltipID = useMemo(() => (withTooltip ? `bar-chart-tooltip-${Math.floor(Math.random() * 1000)}` : null), []);
  BarChartHooks.useDataSeriesVerticalBarDraw({
    svg,
    flattenedDataSeries,
    descriptions: dataSeries.descriptions,
    renderData: dataSeries.renderData,
    scales,
    options,
    tooltipID,
  });
  const elevation = UIHooks.useElevation({ elevated: true });

  return (
    <Container {...{ background, elevation }}>
      <TitleContainer>{renderTitle ? renderTitle() : null}</TitleContainer>
      <ChartContainer>
        <Chart ref={chart}>{tooltipID ? <BarChartTooltip id={tooltipID} className="hidden" /> : null}</Chart>
        {withDescription ? <Descriptions descriptions={dataSeries.descriptions} /> : null}
      </ChartContainer>
    </Container>
  );
}

const BarChartTooltip = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  height: 24px;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  pointer-events: none;
  color: ${colors('white')};
  font-family: ${fontFamily()};
  font-size: ${pixelSize('small')};
  z-index: 9999;
  &.hidden {
    display: none;
  }
`;

export { BarChartHooks } from './effects';
