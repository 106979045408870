import React from 'react';
import { colors } from '@karnott/colors';

export const GrapeVarietyIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <g transform="translate(-1792 -974)">
        <circle cx="15" cy="15" r="15" transform="translate(1792 974)" fill={backgroundColor} />
        <g transform="translate(1800 982)">
          <g transform="translate(0.556 2.446)">
            <path
              d="M264.6,1290.073a2.269,2.269,0,0,1,2.442.362,2.268,2.268,0,1,0-2.442-.362Z"
              transform="translate(-263.829 -1282.004)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M267.951,1293.405a2.268,2.268,0,0,0-.361-2.442,2.268,2.268,0,1,1,.361,2.442Z"
              transform="translate(-264.177 -1282.331)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M265.811,1285.718a2.253,2.253,0,0,1,1.668.37,2.268,2.268,0,1,0-1.983-.632A2.3,2.3,0,0,0,265.811,1285.718Z"
              transform="translate(-263.922 -1281.585)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M269.348,1289.252a2.272,2.272,0,0,1,1.669.37,2.268,2.268,0,1,0-2.615-2.609,2.261,2.261,0,0,1,.387,1.268,2.2,2.2,0,0,1-.032.373,2.29,2.29,0,0,0,.278.336A2.229,2.229,0,0,0,269.348,1289.252Z"
              transform="translate(-264.253 -1281.913)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M275.776,1289.318a2.267,2.267,0,0,0-3.84,1.23,2.258,2.258,0,0,1,.387,1.267,2.29,2.29,0,0,1-.031.373,2.3,2.3,0,0,0,.277.338,2.268,2.268,0,1,0,3.207-3.208Z"
              transform="translate(-264.58 -1282.24)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <path
            d="M275.825,1288.773h0a4.282,4.282,0,0,0,2.328-.7c-.061-.941-1.63-2.419-1.63-2.419a5.164,5.164,0,0,0,2.091-2.293c-.845-.865-2.8-.7-2.8-.7a5.007,5.007,0,0,0,.211-3.773,5.11,5.11,0,0,0-4.053,2.182,2.974,2.974,0,0,0-1.229-1.709,5.022,5.022,0,0,0-1.439,2.859"
            transform="translate(-263.781 -1278.889)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <circle
            cx="2.268"
            cy="2.268"
            r="2.268"
            transform="translate(0 10.311)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (15.835 / 15.846)} height={size} viewBox="0 0 15.835 15.846">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(0.556 2.446)">
          <path
            d="M264.6,1290.073a2.269,2.269,0,0,1,2.442.362,2.268,2.268,0,1,0-2.442-.362Z"
            transform="translate(-263.829 -1282.004)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M267.951,1293.405a2.268,2.268,0,0,0-.361-2.442,2.268,2.268,0,1,1,.361,2.442Z"
            transform="translate(-264.177 -1282.331)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M265.811,1285.718a2.253,2.253,0,0,1,1.668.37,2.268,2.268,0,1,0-1.983-.632A2.3,2.3,0,0,0,265.811,1285.718Z"
            transform="translate(-263.922 -1281.585)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M269.348,1289.252a2.272,2.272,0,0,1,1.669.37,2.268,2.268,0,1,0-2.615-2.609,2.261,2.261,0,0,1,.387,1.268,2.2,2.2,0,0,1-.032.373,2.29,2.29,0,0,0,.278.336A2.229,2.229,0,0,0,269.348,1289.252Z"
            transform="translate(-264.253 -1281.913)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M275.776,1289.318a2.267,2.267,0,0,0-3.84,1.23,2.258,2.258,0,0,1,.387,1.267,2.29,2.29,0,0,1-.031.373,2.3,2.3,0,0,0,.277.338,2.268,2.268,0,1,0,3.207-3.208Z"
            transform="translate(-264.58 -1282.24)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <path
          d="M275.825,1288.773h0a4.282,4.282,0,0,0,2.328-.7c-.061-.941-1.63-2.419-1.63-2.419a5.164,5.164,0,0,0,2.091-2.293c-.845-.865-2.8-.7-2.8-.7a5.007,5.007,0,0,0,.211-3.773,5.11,5.11,0,0,0-4.053,2.182,2.974,2.974,0,0,0-1.229-1.709,5.022,5.022,0,0,0-1.439,2.859"
          transform="translate(-263.781 -1278.889)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="2.268"
          cy="2.268"
          r="2.268"
          transform="translate(0 10.311)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
