import React from 'react';
import styled from 'styled-components';
import { pixelSpacing, spacing } from '@karnott/theme';
import { colors, rgbaColors } from '@karnott/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Track = styled.div`
  position: relative;
  transition: all 500ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${pixelSpacing('xSmall')} 0px;
  width: ${spacing('large') - spacing('xSmall') * 2}px;
  height: ${pixelSpacing('small')};
  border-radius: ${pixelSpacing('large')};
`;

const TrackColor = styled.div`
  transition: all 500ms;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: ${pixelSpacing('large')};
  opacity: ${({ isOn }) => (isOn ? 0.9 : 0.3)};
  background-color: ${({ color }) => color};
`;

const Cursor = styled.div`
  width: ${spacing('small') + spacing('xSmall')}px;
  height: ${spacing('small') + spacing('xSmall')}px;
  border-radius: ${(spacing('small') + spacing('xSmall')) / 2}px;
  display: flex;
  flex-direction: row;
  background-color: ${({ color }) => color};
  box-shadow: 0 0px 3px ${rgbaColors('black', 600, 0.4)}, 0 0px 3px ${rgbaColors('black', 600, 0.4)};
`;

const Push = styled.div`
  transition: all 500ms;
  flex: ${({ isOn }) => (isOn ? '1' : '0')};
`;

export function Toggle({ value, toggle, color }) {
  return (
    <Container>
      <Track onClick={toggle}>
        <TrackColor isOn={value} onClick={toggle} color={color} />
        <Push isOn={value} />
        <Cursor isOn={value} color={color} />
      </Track>
    </Container>
  );
}

export { ToggleHooks } from './effects';
