import React from 'react';
import { colors } from '@karnott/colors';

export const ThresholdIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(7 13)">
        <line
          x2="16.745"
          transform="translate(0 2.512)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M142.045,1265.772c2.091,0,2.091,5.024,4.182,5.024s2.093-5.024,4.186-5.024,2.094,5.024,4.188,5.024,2.1-5.024,4.189-5.024"
          transform="translate(-142.045 -1265.772)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (17.745 / 6.024)} height={size} viewBox="0 0 17.745 6.024">
      <g transform="translate(0.5 0.5)">
        <line
          x2="16.745"
          transform="translate(0 2.512)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M142.045,1265.772c2.091,0,2.091,5.024,4.182,5.024s2.093-5.024,4.186-5.024,2.094,5.024,4.188,5.024,2.1-5.024,4.189-5.024"
          transform="translate(-142.045 -1265.772)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
