import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ChartsHooks } from '../../effects';
import { PerformanceScoreboardHooks } from '../effects';

const Bars = styled.div`
  display: flex;
  flex: 1;
  min-height: ${({ nbRows, barHeight }) => nbRows * barHeight}px;
`;

export function ScoreLine({ dataSerie = { name: '', data: [], meta: {} }, seriesColors = [], barHeight = 20, scales }) {
  const bars = useRef(null);
  const { attributes: chartAttributes, styles: chartStyles } = useMemo(
    () => ({
      attributes: [{ key: 'class', value: 'scoreline' }],
      styles: [{ key: 'background-color', value: 'transparent' }],
    }),
    [],
  );

  const [svg] = ChartsHooks.useSvg(bars, chartAttributes, chartStyles);
  const [defs] = ChartsHooks.useSvgElement({ type: 'defs', parent: svg });
  const [filterUrl] = ChartsHooks.useDropShadowOnSvgDefs({ defs, id: 'scoreline-drop-shadow' });

  const options = useMemo(
    () => ({
      styles: [
        { key: 'fill', value: (d, index) => seriesColors[index] },
        { key: 'filter', value: filterUrl },
      ],
    }),
    [seriesColors, filterUrl],
  );

  PerformanceScoreboardHooks.useDataSerieHorizontalBarDraw(svg, dataSerie, scales, options);

  return <Bars barHeight={barHeight} nbRows={dataSerie.data.length} ref={bars} />;
}
