import { AbstractActionerNoActionTypes, AbstractActionerBuildMutationTypeNotInTypes } from './errors';
import AuthenticationManager from '../authentication/manager';

export default class AbstractActioner {
  constructor({ prefix, prefixSeparator = '_', actionTypes = {} }) {
    this.prefix = prefix;
    this.prefixSeparator = prefixSeparator;
    this.actionTypes = actionTypes;
    this._prefixActionTypes();
  }

  buildMutation(type, payload) {
    if (!(type in this.actionTypes)) throw AbstractActionerBuildMutationTypeNotInTypes();
    return {
      type: this.actionTypes[type],
      payload,
    };
  }

  _prefixActionTypes() {
    const actionTypes = { ...this.actionTypes };
    const actions = Object.keys(actionTypes);

    if (!actions.length) throw AbstractActionerNoActionTypes();

    actions.forEach(actionName => {
      actionTypes[actionName] = `${this.prefix}${this.prefixSeparator}${actionTypes[actionName]}`;
    });
    this.actionTypes = actionTypes;
  }
}

export class AbstractListActioner extends AbstractActioner {
  constructor({ prefix, api }) {
    super({
      prefix,
      actionTypes: {
        SET: 'SET',
        SET_LOADING: 'SET_LOADING',
      },
    });
    this.api = api;
  }

  fetchList(params = {}, query = {}) {
    return dispatch => {
      dispatch(this.buildMutation('SET_LOADING', true));
      return this.api.fetchList({ ...params }, query).then(list => {
        dispatch(this.buildMutation('SET', list));
        return Promise.resolve(list);
      });
    };
  }
}
