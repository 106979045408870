import React from 'react';
import { colors } from '@karnott/colors';

export const MapIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(5 9)">
        <path
          d="M113.255,115.927l-1.038.686-1.593,9.484L117,122.936l6.374,3.161,6.374-3.161-4.43-8.866-3.537,2.543-.288-.19-2-1.325L117,113.452l-1.756,1.161Z"
          transform="translate(-110.624 -113.452)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M116.208,113.6v9.315"
          transform="translate(-109.834 -113.431)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="1.593"
          y2="9.484"
          transform="translate(11.154 3.161)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (20.121 / 13.721)} height={size} viewBox="0 0 20.121 13.721">
      <g transform="translate(0.5 0.5)">
        <path
          d="M113.255,115.927l-1.038.686-1.593,9.484L117,122.936l6.374,3.161,6.374-3.161-4.43-8.866-3.537,2.543-.288-.19-2-1.325L117,113.452l-1.756,1.161Z"
          transform="translate(-110.624 -113.452)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M116.208,113.6v9.315"
          transform="translate(-109.834 -113.431)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="1.593"
          y2="9.484"
          transform="translate(11.154 3.161)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
