import { exportParamsFromHash } from './utils';

export default class AuthenticationManager {
  static instance = null;

  constructor({
    authenticationHeaderKey,
    oauthServerBaseUrl,
    clientId,
    redirectPath,
    storage = localStorage,
    onAuthExpired,
  }) {
    this.credentials = null;
    this.authenticationHeaderKey = authenticationHeaderKey;
    this.oauthServerBaseUrl = oauthServerBaseUrl;
    this.clientId = clientId;
    this.redirectPath = redirectPath;
    this.storage = storage;
    this.onAuthExpired = onAuthExpired;

    this._storageKey = '__authenticationManager__';

    AuthenticationManager.instance = this;
    this.restoreAuth();
    this.checkForAuthInUrl();
  }

  startOauth2Workflow() {
    this.storage.setItem(this._storageKey + `origin__url`, window.location.href);
    this.openOAuthUri();
  }

  setAccessToken(token) {
    this.access_token = token;
  }

  getAuthenticationHeaders() {
    if (!this.credentials || !this.credentials.access_token || !this.credentials.token_type) return {};
    if (new Date() > new Date(this.credentials?.expiration)) {
      this.logout();
    }
    return {
      Authorization: `${this.credentials.token_type} ${this.credentials.access_token}`,
    };
  }

  openOAuthUri() {
    window.location.replace(`${this.oauthServerBaseUrl}?response_type=token&client_id=${this.clientId}`);
  }

  restoreAuth() {
    let credentials = this.storage.getItem(this._storageKey + 'credentials');
    credentials = credentials ? JSON.parse(credentials) : null;
    if (!credentials) return null;
    if (new Date() > new Date(credentials?.expiration)) {
      this.logout();
    } else {
      this.credentials = credentials;
    }

    return this.credentials;
  }

  logout() {
    if (typeof this.onAuthExpired === 'function') {
      this.onAuthExpired(this.credentials);
    }
    this.storage.removeItem(this._storageKey + 'credentials');
    this.credentials = null;
  }

  checkForAuthInUrl() {
    const originUri = this.storage.getItem(this._storageKey + `origin__url`);
    if (originUri && window.location.pathname.includes(this.redirectPath)) {
      const payload = exportParamsFromHash(window.location.hash);
      this.storage.removeItem(this._storageKey + `origin__url`);
      let expiration = new Date(Date.now() + parseInt(payload.expires_in) * 1000);
      this.storage.setItem(
        this._storageKey + `credentials`,
        JSON.stringify({ ...payload, expiration: expiration.toISOString() }),
      );
      window.location.replace(originUri);
    }
  }

  static getInstance() {
    if (!AuthenticationManager.instance) throw Error('not init auth while calling get instance');
    return AuthenticationManager.instance;
  }
}
