import React from 'react';
import { colors } from '@karnott/colors';

export const NotificationIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <g transform="translate(-1636 -13)">
        <circle cx="15" cy="15" r="15" transform="translate(1636 13)" fill={backgroundColor} />
        <g transform="translate(1527.9 -1210.527)">
          <g>
            <path
              d="M122.094,1244.3v.454a1.362,1.362,0,0,0,2.724,0v-.454Z"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M128.45,1241.7c0-4.058.013-3.114-.038-3.531h0a4.994,4.994,0,0,0-2.518-3.739h0a5,5,0,0,0-4.875,0h0a4.994,4.994,0,0,0-2.518,3.739h0c-.051.407-.039-.511-.039,3.531"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M117.1,1241.913v2.387h12.712v-2.387Z"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <circle
              cx="1.135"
              cy="1.135"
              r="1.135"
              transform="translate(122.352 1231.527)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (13.712 / 15.589)} height={size} viewBox="0 0 13.712 15.589">
      <g transform="translate(-116.6 -1231.027)">
        <g>
          <path
            d="M122.094,1244.3v.454a1.362,1.362,0,0,0,2.724,0v-.454Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M128.45,1241.7c0-4.058.013-3.114-.038-3.531h0a4.994,4.994,0,0,0-2.518-3.739h0a5,5,0,0,0-4.875,0h0a4.994,4.994,0,0,0-2.518,3.739h0c-.051.407-.039-.511-.039,3.531"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M117.1,1241.913v2.387h12.712v-2.387Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <circle
            cx="1.135"
            cy="1.135"
            r="1.135"
            transform="translate(122.352 1231.527)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
