import { set } from 'd3';
import { useCallback, useEffect, useMemo, useState } from 'react';

function useTracksFilter(dataSeries) {
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    setFilters(dataSeries.descriptions.map(() => 0));
  }, [dataSeries.descriptions]);

  const hideDataTrack = useCallback(
    trackId => {
      setFilters(filters.map((value, id) => (id === trackId ? !value : value)));
    },
    [filters, setFilters],
  );

  const filteredDataSeries = useMemo(() => {
    if (!filters) return dataSeries;
    return {
      ...dataSeries,
      descriptions: dataSeries.descriptions.filter((value, id) => !filters[id]),
      series: dataSeries.series.map(serie => ({
        ...serie,
        data: serie.data.filter((value, id) => !filters[id]),
      })),
    };
  }, [filters, dataSeries]);

  return [filteredDataSeries, filters, hideDataTrack];
}

export const TracksFilterHooks = {
  useTracksFilter,
};
