import { useCallback, useEffect, useRef, useState } from 'react';

function useAsyncCall(call, onMount, initialValue = null) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);

  const trigger = useCallback(
    (...args) => {
      setIsLoading(true);
      call(...args)
        .then((response) => {
          setValue(response);
          setIsLoading(false);
          setHasBeenCalled(true);
        })
        .catch((error) => {
          setHasBeenCalled(true);
          setIsLoading(false);
          setError(error);
        });
    },
    [call, setIsLoading, setValue, setError],
  );

  useEffect(() => {
    if (onMount && !hasBeenCalled) trigger();
    return () => {};
  }, [onMount, trigger, hasBeenCalled]);

  return [trigger, value, isLoading, error, hasBeenCalled];
}

function useAsyncCallOnChange(value, onChange) {
  const call = useRef(onChange);
  useEffect(() => {
    call.current && call.current(value);
    return () => {};
  }, [value, call]);
}

function useDownloadApplicationType() {
  return useCallback(
    (format) =>
      ({
        csv: 'text/csv;charset=utf-8',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }[format]),
    [],
  );
}

function useDownloadBlobFormater() {
  return useCallback(
    (format) =>
      ({
        csv: (data) => ['\uFEFF' + data],
        xlsx: (data) => [data],
      }[format]),
    [],
  );
}

function useDownloadToFormat(getData, getDownloadName, saveAs) {
  const downloadApplicationType = useDownloadApplicationType();
  const downloadBlobFormater = useDownloadBlobFormater();
  const download = useCallback(
    (format) => {
      return getData(format).then((data) => {
        const blobParts = downloadBlobFormater(format)(data);
        const blob = new Blob(blobParts, {
          type: downloadApplicationType(format),
        });
        saveAs(blob, getDownloadName(format));
      });
    },
    [saveAs, downloadApplicationType, downloadBlobFormater, getData, getDownloadName],
  );

  const [tiggerDownload, , isLoading, error] = useAsyncCall(download, false);

  return [tiggerDownload, isLoading, error];
}

export const UtilsHooks = {
  useAsyncCall,
  useAsyncCallOnChange,
  useDownloadApplicationType,
  useDownloadBlobFormater,
  useDownloadToFormat,
};
