import { AbstractActioner } from '../abstract';
import AuthenticationManager from './manager';

export default class Actioner extends AbstractActioner {
  constructor({ prefix }) {
    super({
      prefix,
      actionTypes: {
        SET: 'SET',
        RE_INIT: 'RE_INIT',
      },
    });
  }

  startOAuth2Workflow() {
    return dispatch => {
      return AuthenticationManager.getInstance()
        .startOauth2Workflow()
        .then(credentials => {
          return dispatch(this.setCredentials(credentials));
        });
    };
  }

  restoreAuth() {
    return dispatch => {
      return dispatch(this.setCredentials(AuthenticationManager.getInstance().restoreAuth()));
    };
  }

  logout() {
    return dispatch => {
      dispatch(this.buildMutation(this.actionTypes.RE_INIT));
      AuthenticationManager.getInstance().logout();
      return Promise.resolve();
    };
  }

  setCredentials(credentials) {
    return dispatch => {
      dispatch(this.buildMutation(this.actionTypes.SET, credentials));
      return Promise.resolve(credentials);
    };
  }
}
