import { useMemo } from 'react';

function useDescriptionsLists(descriptions) {
  const labels = useMemo(() => {
    return descriptions.map(({ label }) => label);
  }, [descriptions]);

  const colors = useMemo(() => {
    return descriptions.map(({ color }) => color);
  }, [descriptions]);

  return { labels, colors };
}

export const DescriptionsHooks = {
  useDescriptionsLists,
};
