import React from 'react';
import { colors } from '@karnott/colors';

export const SpreaderIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(-1082.082 -783.193)">
      <path
        d="M1118.884,806.91h.671a1.223,1.223,0,0,1,1.219,1.219h0a1.222,1.222,0,0,1-1.219,1.219h-9.747"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <g>
        <g>
          <g>
            <path
              d="M1101.786,803.162a6.186,6.186,0,1,0,6.187,6.186A6.186,6.186,0,0,0,1101.786,803.162Zm0,8.57a2.384,2.384,0,1,1,2.384-2.384A2.384,2.384,0,0,1,1101.786,811.732Z"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
      <g>
        <line
          x2="10.151"
          transform="translate(1107.973 809.348)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="1.386"
          transform="translate(1093.91 809.348)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M1118.884,806.91V802.6a2.282,2.282,0,0,0-2.282-2.281h-20.183"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <path
        d="M1096.419,800.316l.731-2.634a.82.82,0,0,0-.568-1.007l-1.377-.452a.818.818,0,0,0-1.006.569l-.658,2.37a1.523,1.523,0,0,1-1.307,1.106l-.456.048.368.279a1.522,1.522,0,0,1,.547,1.62h0a1.523,1.523,0,0,1-1.307,1.106l-.456.048.368.279a1.522,1.522,0,0,1,.547,1.62h0a1.522,1.522,0,0,1-1.307,1.106l-.456.048.369.279a1.522,1.522,0,0,1,.546,1.62l-.885,3.188a.82.82,0,0,0,.569,1.006l1.376.453a.82.82,0,0,0,1.006-.569l.847-3.051"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <g>
        <path
          d="M1104.475,800.316l2.23-2.832a1.648,1.648,0,0,1,2.533-.068l2.547,2.9"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M1111.785,800.316l1.653-2.1a1.222,1.222,0,0,1,1.879-.051l1.888,2.151"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M1099.055,800.316l1.653-2.1a1.221,1.221,0,0,1,1.878-.051l1.889,2.151"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
