import React from 'react';
import { colors } from '@karnott/colors';

export const GroupIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(6 9)">
        <g transform="translate(-463.965 -1261.996)">
          <g>
            <path
              d="M470.957,1269.133a5.7,5.7,0,0,1,.937.271,5.058,5.058,0,0,1,1.761,1.124,4.9,4.9,0,0,1,1.089,1.74,4.646,4.646,0,0,1,.248.882H463.965a4.492,4.492,0,0,1,.243-.882,4.949,4.949,0,0,1,1.106-1.74,5.009,5.009,0,0,1,1.748-1.124,5.686,5.686,0,0,1,.734-.227"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <ellipse
            cx="2.821"
            cy="3.743"
            rx="2.821"
            ry="3.743"
            transform="translate(466.664 1261.996)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-463.965 -1261.996)">
          <g>
            <path
              d="M473.775,1270.612a4.375,4.375,0,0,1,1.2-.694,4.863,4.863,0,0,1,.634-.195m2.726-.039a5.038,5.038,0,0,1,.809.234,4.364,4.364,0,0,1,1.519.97,4.23,4.23,0,0,1,.94,1.5,4.1,4.1,0,0,1,.214.761h-6.827"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <ellipse
            cx="2.434"
            cy="3.229"
            rx="2.434"
            ry="3.229"
            transform="translate(474.633 1263.527)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (18.854 / 12.154)} height={size} viewBox="0 0 18.854 12.154">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(-463.965 -1261.996)">
          <g>
            <path
              d="M470.957,1269.133a5.7,5.7,0,0,1,.937.271,5.058,5.058,0,0,1,1.761,1.124,4.9,4.9,0,0,1,1.089,1.74,4.646,4.646,0,0,1,.248.882H463.965a4.492,4.492,0,0,1,.243-.882,4.949,4.949,0,0,1,1.106-1.74,5.009,5.009,0,0,1,1.748-1.124,5.686,5.686,0,0,1,.734-.227"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <ellipse
            cx="2.821"
            cy="3.743"
            rx="2.821"
            ry="3.743"
            transform="translate(466.664 1261.996)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(-463.965 -1261.996)">
          <g>
            <path
              d="M473.775,1270.612a4.375,4.375,0,0,1,1.2-.694,4.863,4.863,0,0,1,.634-.195m2.726-.039a5.038,5.038,0,0,1,.809.234,4.364,4.364,0,0,1,1.519.97,4.23,4.23,0,0,1,.94,1.5,4.1,4.1,0,0,1,.214.761h-6.827"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <ellipse
            cx="2.434"
            cy="3.229"
            rx="2.434"
            ry="3.229"
            transform="translate(474.633 1263.527)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
