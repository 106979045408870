import React from 'react';
import { colors } from '@karnott/colors';

export const FieldRollerIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(-1083.14 -852.609)">
      <path
        d="M1092.38,882.951h0a.737.737,0,0,1-.735-.735v-3.568a.737.737,0,0,1,.735-.735h7.891a.737.737,0,0,1,.735.735v3.568a.737.737,0,0,1-.735.735Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1110.586,882.951h0a.738.738,0,0,1-.735-.735v-3.568a.738.738,0,0,1,.735-.735h7.891a.737.737,0,0,1,.734.735v3.568a.737.737,0,0,1-.734.735Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1091.533,880.171h-2.026a.368.368,0,0,1-.367-.367v-2.988a1.656,1.656,0,0,1,1.651-1.651h11.069a1.656,1.656,0,0,1,1.651,1.651V879.8a.368.368,0,0,1-.367.367h-2.03"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1109.775,880.171h-2.063a.368.368,0,0,1-.366-.367v-2.988a1.655,1.655,0,0,1,1.65-1.651h11.07a1.656,1.656,0,0,1,1.651,1.651V879.8a.369.369,0,0,1-.368.367h-2.03"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <g>
        <path
          d="M1096.55,875.165l6.1-5.011h5.781l6.1,4.882"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M1108.421,870.153h-5.761l2.19-6.545h1.382Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
