import { colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { ClusterIcon, SearchIcon } from '@karnott/icons';
import { Checkbox } from '../checkbox';
import { InputEffects } from '../input';
import { elevation, fontFamily, pixelSize, pixelSpacing, size, spacing } from '@karnott/theme';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { FormHooks } from '../effects';

const Container = styled.div`
  position: relative;
  font-family: ${fontFamily()};
  flex: ${({ full }) => (full ? 1 : 'none')};
`;

const Handler = styled.div`
  display: flex;
  padding: ${pixelSpacing('small')};
  flex-direction: row;
  align-items: center;
  border: solid 1px ${colors('grey', 'light')};
  border-radius: ${pixelSpacing('xSmall')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ disabled, opened }) => {
    if (disabled) {
      return colors('grey', 'lighter');
    }
    if (opened) {
      return colors('grey');
    }
    return colors('white');
  }};
  transition: all 0.1s linear;
  > svg {
    margin-right: ${pixelSpacing()};
  }
  &:hover {
    ${({ disabled }) =>
      !disabled &&
      `background-color: ${colors('grey', 'dark')};
    border-color: ${colors('grey', 'dark')};`}
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  color: ${({ titleColor }) => titleColor};
  box-sizing: border-box;
`;

const Menu = styled.div`
  position: absolute;
  top: ${({ topOffset }) => `${topOffset + spacing('xSmall')}px`};
  ${({ alignRight }) => (!alignRight ? `left: 0;` : `right: 0;`)}
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  padding: ${pixelSpacing('small')};
  box-shadow: ${elevation({ elevated: true })};
  border-radius: ${pixelSpacing('xSmall')};
  background-color: ${colors('white')};
  font-family: ${fontFamily()};
  max-height: 45vh;
  min-width: calc(100% - ${pixelSpacing()});
  z-index: 1;
`;

const ListItems = styled.div`
  align-self: stretch;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  > * {
    white-space: nowrap;
    transition: background-color 50ms linear;
    border-radius: 4px;
    &:hover {
      background-color: ${colors('grey', 100)};
    }
    min-height: ${spacing() + spacing('small')}px;
  }
`;

const ListItem = styled.div`
  box-sizing: border-box;
  padding: ${pixelSpacing('xSmall')} ${pixelSpacing('xSmall')};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  color: ${colors('black')};
  > * {
    pointer-events: none;
  }
`;

const ListItemTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: ${pixelSpacing('xSmall')};
`;

const BottomActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${pixelSpacing('xSmall')};
  padding: ${pixelSpacing('xSmall')} ${pixelSpacing('small')};
  background-color: ${colors('orange')};
  color: ${colors('white')};
  cursor: pointer;
`;

const SelectorsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${colors('grey', 'light')};
  font-size: ${pixelSize('small')};
  margin-bottom: ${pixelSpacing('small')};
`;

const Selector = styled.div`
  padding: ${pixelSpacing('xSmall')} ${pixelSpacing('small')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${colors('grey', 200)};
  border-radius: ${pixelSpacing('xSmall')};
  padding: ${pixelSpacing('xSmall')} ${pixelSpacing('small')};
  margin-bottom: ${pixelSpacing('small')};
  background-color: ${colors('grey', 200)};
`;

const Input = styled.input`
  padding: 0;
  font-size: ${pixelSize()};
  color: ${colors('grey', 'dark')};
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  vertical-align: center;
  font-family: ${fontFamily()};
  margin-left: ${pixelSpacing('xSmall')};
  ::placeholder {
    color: ${colors('grey', 'light')};
  }
`;

const Title = styled.span`
  opacity: ${({ opened, hide }) => (opened || hide ? 0 : 1)};
  font-size: ${pixelSize()};
  margin-left: ${pixelSpacing('xSmall')};
`;

const Selection = styled.span`
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  font-size: ${pixelSize()};
  margin-left: ${pixelSpacing('xSmall')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
`;

export function List({
  alignItemsOnRightSide,
  bottomAction,
  clearSelection,
  clearSelectionLabel,
  closeList,
  displayedList,
  getItemLabel,
  handlerRef,
  id,
  isOpen,
  list,
  multiple,
  onItemClick,
  placeholder,
  query,
  renderEmptyQueryPlaceholder,
  selectAll,
  selectAllLabel,
  selection,
  setQuery,
  toggleItemSelection,
  withSearch,
  withClearSelectionItem,
  withSelectors,
  areItemsEquals = (a, b) => a === b,
}) {
  const inputRef = useRef();

  const onQueryChangeCB = InputEffects.useInputChangeCallback(setQuery);

  useEffect(() => {
    if (isOpen) {
      inputRef.current && inputRef.current.focus();
    }
  }, [isOpen]);

  return isOpen ? (
    <Menu topOffset={handlerRef.current && handlerRef.current.clientHeight} alignRight={alignItemsOnRightSide}>
      {withSearch ? (
        <InputContainer>
          <SearchIcon size={size('large')} color={colors('grey', 'light')} />
          <Input ref={inputRef} onChange={onQueryChangeCB} value={query} placeholder={placeholder} />
        </InputContainer>
      ) : null}
      {withSelectors ? (
        <SelectorsContainer>
          <Selector onClick={selectAll}>{selectAllLabel}</Selector>/
          <Selector onClick={clearSelection}>{clearSelectionLabel}</Selector>
        </SelectorsContainer>
      ) : null}
      {!list || list.length === 0 ? null : (
        <ListItems id={id ? `${id}-items` : undefined}>
          {withClearSelectionItem && clearSelection && displayedList.length !== 0 ? (
            <ListItem
              clearSelection
              selected={!selection || selection.length === 0}
              onClick={() => {
                clearSelection();
                if (!multiple) {
                  closeList();
                }
              }}
              key="clear-selection"
            >
              <ListItemTitle>{clearSelectionLabel}</ListItemTitle>
            </ListItem>
          ) : null}
          {displayedList && displayedList.length === 0
            ? renderEmptyQueryPlaceholder
              ? renderEmptyQueryPlaceholder(query)
              : null
            : displayedList.map(item => {
                const itemLabel = getItemLabel(item);
                const selected = !!(selection || []).find(other => areItemsEquals(item, other));
                return multiple ? (
                  <Checkbox
                    {...{
                      checked: selected,
                      key: itemLabel,
                      label: itemLabel,
                      onChange: () => {
                        toggleItemSelection(item);
                        onItemClick && onItemClick(item);
                      },
                    }}
                  />
                ) : (
                  <ListItem
                    {...{
                      key: itemLabel,
                      selected,
                      onClick: () => {
                        toggleItemSelection(item);
                        closeList();
                        onItemClick && onItemClick(item);
                      },
                    }}
                  >
                    <ListItemTitle>{itemLabel}</ListItemTitle>
                  </ListItem>
                );
              })}
        </ListItems>
      )}
      {bottomAction ? (
        <BottomActionContainer onClick={bottomAction.fn}>{bottomAction.label}</BottomActionContainer>
      ) : null}
    </Menu>
  ) : null;
}

export function ListSelector({
  alignItemsOnRightSide,
  bottomAction,
  clearSelection,
  clearSelectionLabel,
  disabled,
  full,
  getItemLabel,
  getSelectionLabel,
  Icon = ClusterIcon,
  id,
  list,
  multiple,
  onItemClick,
  placeholder,
  renderEmptyQueryPlaceholder,
  selectAll,
  selectAllLabel,
  selection,
  title,
  toggleItemSelection,
  withSearch,
  withClearSelectionItem,
  withSelectors,
  areItemsEquals = (a, b) => a === b,
}) {
  const [ref, refHovered] = UIHooks.useHover(disabled);
  const containerRef = useRef(null);
  const [isOpen, open, closeList, toggle] = UIHooks.useOpenCloseState(false);
  const [displayedList, query, setQuery] = FormHooks.useListQueryFilter(list, getItemLabel);

  const label = useMemo(() => {
    return getSelectionLabel ? getSelectionLabel(selection) : '';
  }, [getSelectionLabel, selection]);

  const onRequestCloseCallback = useCallback(() => {
    setQuery('');
    closeList();
  }, [closeList, setQuery, selection]);

  const titleColor = useMemo(() => {
    if (disabled) {
      return colors('grey', 'dark');
    }
    if (refHovered || isOpen) {
      return colors('white');
    }
    if (selection && selection.length) {
      return colors('black');
    }
    return colors('grey');
  }, [refHovered, selection, disabled, isOpen]);

  const handleDomClick = useCallback(
    e => {
      if (disabled) return;
      if (!containerRef.current || !containerRef.current.contains(e.target)) {
        onRequestCloseCallback();
        document.removeEventListener('click', handleDomClick);
      } else {
        open();
      }
    },
    [containerRef, disabled, onRequestCloseCallback, open],
  );

  const handlerClick = useCallback(
    e => {
      if (!disabled) {
        e.stopPropagation();
        if (isOpen) {
          document.removeEventListener('click', handleDomClick);
        } else {
          document.addEventListener('click', handleDomClick);
        }
        toggle();
      }
    },
    [disabled, handleDomClick, isOpen, toggle],
  );

  const toggleItemCB = useCallback(
    item => {
      if (!multiple) {
        closeList();
      }
      toggleItemSelection(item);
    },
    [toggleItemSelection],
  );

  const showSelection = useMemo(() => selection && selection.length, [selection]);

  return (
    <Container ref={containerRef} {...{ full, id }}>
      <Handler onClick={handlerClick} opened={isOpen} {...{ disabled, ref }} id={id ? `${id}-handler` : undefined}>
        <Icon size={size('large')} color={titleColor} {...{ disabled }} />
        <TitleContainer {...{ titleColor }}>
          {showSelection ? <Selection>{label}</Selection> : <Title>{title}</Title>}
        </TitleContainer>
      </Handler>
      <List
        {...{
          alignItemsOnRightSide,
          bottomAction,
          clearSelection,
          clearSelectionLabel,
          closeList,
          displayedList,
          getItemLabel,
          handlerRef: containerRef,
          id,
          isOpen,
          list,
          multiple,
          onItemClick,
          placeholder,
          query,
          renderEmptyQueryPlaceholder,
          selectAll,
          selectAllLabel,
          selection,
          setQuery,
          toggleItemSelection: toggleItemCB,
          withSearch,
          withClearSelectionItem,
          withSelectors,
          areItemsEquals,
        }}
      />
    </Container>
  );
}
