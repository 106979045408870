import AbstractActioner from '../actioner';

export default class Actioner extends AbstractActioner {
  constructor({ prefix, api }) {
    super({
      prefix,
      actionTypes: {
        SET: 'SET',
        SET_LOADING: 'SET_LOADING',
      },
    });
    this.api = api;
  }

  read(params, querry) {
    return dispatch => {
      dispatch(this.buildMutation('SET_LOADING', true));
      return this.api.read(params, querry).then(value => {
        dispatch(this.buildMutation('SET', value));
        return Promise.resolve(value);
      });
    };
  }
}
