import { AbstractListActioner } from '../abstract/actioner';

export default class Actioner extends AbstractListActioner {
  constructor({ prefix, api }) {
    super({
      prefix,
      api,
      authenticated: true,
    });
  }
}
