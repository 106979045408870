import React from 'react';
import { colors } from '@karnott/colors';

export const EyeIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(6 10)">
        <path
          d="M267.123,16.309a10.5,10.5,0,0,0-8.584,4.637,1.293,1.293,0,0,0,0,1.478,10.264,10.264,0,0,0,17.169,0,1.289,1.289,0,0,0,0-1.476A10.493,10.493,0,0,0,267.123,16.309Z"
          transform="translate(-258.307 -16.309)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="5.376"
          cy="5.376"
          rx="5.376"
          ry="5.376"
          transform="translate(3.44)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="2.364"
          cy="2.364"
          rx="2.364"
          ry="2.364"
          transform="translate(6.452 3.012)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (18.706 / 11.752)} height={size} viewBox="0 0 18.706 11.752">
      <g transform="translate(0.536 0.5)">
        <path
          d="M267.123,16.309a10.5,10.5,0,0,0-8.584,4.637,1.293,1.293,0,0,0,0,1.478,10.264,10.264,0,0,0,17.169,0,1.289,1.289,0,0,0,0-1.476A10.493,10.493,0,0,0,267.123,16.309Z"
          transform="translate(-258.307 -16.309)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="5.376"
          cy="5.376"
          rx="5.376"
          ry="5.376"
          transform="translate(3.44)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="2.364"
          cy="2.364"
          rx="2.364"
          ry="2.364"
          transform="translate(6.452 3.012)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
