import React from 'react';
import { colors } from '@karnott/colors';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  font-size: ${pixelSize()};
  align-items: center;
  color: ${colors('grey', 400)};
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer;
`;

const Label = styled.span`
  margin-left: ${pixelSpacing('small')};
  color: ${colors('grey')};
  font-family: ${fontFamily()};
`;

const RadioCircle = styled.span`
  position: relative;
  box-sizing: border-box;
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pixelSpacing('regular')};
  height: ${pixelSpacing('regular')};
  background-color: ${colors('white')};
  border-radius: ${pixelSpacing('small')};
  border-style: solid;
  border-image: initial;
  transition: border-color 180ms ease-in-out 0s;
  outline: none;
  border-width: 1px;
  border-color: ${({ disabled, checked }) => {
    if (disabled) return colors('grey', 200);
    if (checked) return colors('green');
    return colors('grey', 300);
  }};
  &:hover {
    > span {
      background-color: ${({ disabled, checked }) => {
        if (disabled) return colors('grey', 200);
        if (checked) return colors('green');
        return colors('grey', 300);
      }};
      width: 10px;
      height: 10px;
    }
  }
`;

const RadioButton = styled.span`
  box-sizing: border-box;
  content: '';
  display: block;
  width: ${({ checked }) => (checked ? '10px' : 0)};
  height: ${({ checked }) => (checked ? '10px' : 0)};
  border-radius: ${pixelSpacing('small')};
  transition: width 180ms ease-in-out 0s, height 180ms ease-in-out 0s, background-color 180ms ease-in-out 0s;
  outline: none;
  background-color: ${({ disabled, checked }) => {
    if (disabled) return colors('grey', 200);
    if (checked) return colors('green');
    return colors('grey', 300);
  }};
`;

const RadiosContainer = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  > * {
    margin: ${({ vertical }) => {
      if (vertical) return `0 0 ${pixelSpacing('small')} 0`;
      return `0 ${pixelSpacing('regular')} 0 0`;
    }};
  }
`;

function Radio({ checked, label, onClick }) {
  return (
    <Container onClick={() => onClick(label)}>
      <RadioCircle {...{ checked }}>
        <RadioButton {...{ checked }} />
      </RadioCircle>
      {label ? <Label>{label}</Label> : null}
    </Container>
  );
}

function Radios({ items, selection, onChange, vertical }) {
  return (
    <RadiosContainer {...{ vertical }}>
      {items.map((item, index) => (
        <Radio key={`${item}-${index}`} checked={!!selection?.includes(item)} label={item} onClick={onChange} />
      ))}
    </RadiosContainer>
  );
}

export { Radio, Radios };
