export const EQUIPMENT_CONSTANTS = {
  TYPES: {
    andaineuse: 'andaineuse',
    broy: 'broy',
    charrue: 'charrue',
    epand: 'epand',
    faneuse: 'faneuse',
    faucheuse: 'faucheuse',
    haymaking: 'haymaking',
    lisier: 'lisier',
    misc: 'misc',
    plomb: 'plomb',
    presse: 'presse',
    pulve: 'pulve',
    recolt: 'recolt',
    remork: 'remork',
    semoir: 'semoir',
    snow_removal: 'snow_removal',
    sol_combined: 'sol_combined',
    sol: 'sol',
    tractor: 'tractor',
  },
};

export const PARCELS_CONSTANTS = {
  CATEGORY: {
    ANONYMOUS: 'anonymous',
    FIELD: 'field',
    FARMHOUSE: 'farmhouse',
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
    DELETED: 'DELETED',
    RENT_INACTIVE: 'INACTIVE',
    RENT_OVER: 'RENT_OVER',
    BEING_RENT: 'BEING_RENT',
    BEING_RENT_ACTIVE: 'BEING_RENT_ACTIVE',
  },
};
