import React from 'react';
import { colors } from '@karnott/colors';

export const RemorkIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(-993.403 -782.708)">
      <circle cx="0.619" cy="0.619" r="0.619" transform="translate(1005.036 811.955)" fill={color} />
      <path
        id="Tracé_220"
        data-name="Tracé 220"
        d="M.619,0A.619.619,0,1,1,0,.619.619.619,0,0,1,.619,0Z"
        transform="translate(1015.158 811.955)"
        fill={color}
      />
      <line
        x1="29.079"
        transform="translate(999.589 802.02)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <g>
        <ellipse
          cx="3.221"
          cy="3.305"
          rx="3.221"
          ry="3.305"
          transform="translate(1002.434 809.41)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <g>
        <ellipse
          cx="3.221"
          cy="3.305"
          rx="3.221"
          ry="3.305"
          transform="translate(1012.654 809.41)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <path
        d="M1028.077,797.321h-22.105c-.251-.694-.729-1.613-1.49-1.613h-4.222a.857.857,0,0,0-.857.857v1.511c0,.018.01.033.011.051s-.011.032-.011.051v8.17a.857.857,0,0,0,.857.857h27.817a.857.857,0,0,0,.857-.857v-8.17A.857.857,0,0,0,1028.077,797.321Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1003.738,810.059h-1.773l-1-2.551"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x1="6.222"
        transform="translate(1007.571 810.059)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1027.267,807.78l-.9,2.28h-8.338"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1027.43,807.205l5.345,2.854h-6.4"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
