import { AbstractService } from '../abstract';
import Actioner from './actioner';
import Reducer from './reducer';
import Selectors from './selectors';
import Api from './api';
import { DEFAULT_ACCESSOR_CHAIN, DEFAULT_ACTION_PREFIX, DEFAULT_SERVICE_NAME } from './constants';

export default class PlotsService extends AbstractService {
  constructor({
    serviceName = DEFAULT_SERVICE_NAME,
    actionPrefix = DEFAULT_ACTION_PREFIX,
    accessorChain = DEFAULT_ACCESSOR_CHAIN,
    apiBase,
  }) {
    const api = new Api({ base: apiBase });
    const actioner = new Actioner({ prefix: actionPrefix, api });
    const reducer = new Reducer({ actioner });
    const selectors = new Selectors({ accessorChain });
    super({
      name: serviceName,
      actioner,
      reducer,
      selectors,
    });
  }
}
