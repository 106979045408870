import { createSelector } from 'reselect';

export default class AbstractSelectors {
  constructor({ accessorChain = [] }) {
    this.accessorChain = accessorChain;
    this._initBaseSelectors();
  }

  _initBaseSelectors() {
    const getState = state => {
      const getItemKey = (item, accessorIndex) => {
        if (!item) return null;
        if (accessorIndex < this.accessorChain.length - 1) {
          return getItemKey(item[this.accessorChain[accessorIndex]], accessorIndex + 1);
        }
        return item[this.accessorChain[accessorIndex]];
      };

      return getItemKey(state, 0);
    };

    this.getState = getState;
  }

  _addBaseSelectors(selectors) {
    selectors.forEach(({ name, selector }) => {
      this[name] = selector.bind(this);
    });
  }
}

export class AbstractListSelectors extends AbstractSelectors {
  constructor({ accessorChain = [] }) {
    super({ accessorChain });
    this._addBaseSelectors([
      {
        name: 'getList',
        selector: createSelector([this.getState], state => state?.list),
      },
      {
        name: 'loading',
        selector: createSelector([this.getState], state => state?.listLoading),
      },
    ]);
  }
}
