import React from 'react';
import { colors } from '@karnott/colors';

export const BoardIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(7 9)">
        <g transform="translate(0 4.148)">
          <path
            d="M176.851,117.113h-15.58"
            transform="translate(-161.271 -117.113)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M176.851,120.87h-15.58"
            transform="translate(-161.271 -116.521)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <path
          d="M161.271,118.1v-4.117a.5.5,0,0,1,.537-.456h14.505a.5.5,0,0,1,.537.456v11.733a.5.5,0,0,1-.536.456H161.808a.5.5,0,0,1-.537-.456V118.1Z"
          transform="translate(-161.271 -113.53)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y2="12.645"
          transform="translate(5.53)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (16.58 / 13.645)} height={size} viewBox="0 0 16.58 13.645">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(0 4.148)">
          <path
            d="M176.851,117.113h-15.58"
            transform="translate(-161.271 -117.113)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M176.851,120.87h-15.58"
            transform="translate(-161.271 -116.521)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <path
          d="M161.271,118.1v-4.117a.5.5,0,0,1,.537-.456h14.505a.5.5,0,0,1,.537.456v11.733a.5.5,0,0,1-.536.456H161.808a.5.5,0,0,1-.537-.456V118.1Z"
          transform="translate(-161.271 -113.53)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y2="12.645"
          transform="translate(5.53)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
