import React from 'react';
import { colors } from '@karnott/colors';

export const WorkIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(-237.177 -1251.825)">
        <path
          d="M257.5,1264.994a.115.115,0,0,1-.111.119h-3.008a.116.116,0,0,1-.112-.119v-3.051"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M257.636,1264.994v7.359a.435.435,0,0,1-.464.4h-8.531a.435.435,0,0,1-.464-.4v-10.134a.433.433,0,0,1,.464-.394h5.624Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (12.459 / 13.923)} height={size} viewBox="0 0 12.459 13.923">
      <g transform="translate(0.5 0.5)">
        <path
          d="M258.107,1265.571a.137.137,0,0,1-.132.142H254.4a.138.138,0,0,1-.133-.142v-3.628"
          transform="translate(-246.788 -1261.825)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M259.636,1265.574v8.706a.522.522,0,0,1-.562.467H248.739a.522.522,0,0,1-.562-.467v-11.99a.521.521,0,0,1,.562-.466h6.813Z"
          transform="translate(-248.177 -1261.825)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
