import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import { ChartsHooks } from '../effects';

function useDataSeriesVerticalScale(container, flattenedDataSeries, max, margin = {}) {
  const width = container.current ? container.current.clientWidth - (margin.left || 0) - (margin.right || 0) : 0;
  const height = container.current ? container.current.clientHeight - (margin.top || 0) - (margin.bottom || 0) : 0;

  const xRange = useMemo(() => [0, width], [width]);
  const xDomain = useMemo(() => flattenedDataSeries.map(({ name }) => name), [flattenedDataSeries]);

  const yRange = useMemo(() => [0, height], [height]);
  const yDomain = useMemo(() => [0, max], [max]);

  const y = useMemo(() => d3.scaleLinear(yDomain, yRange), [yRange, yDomain]);
  const x = useMemo(() => d3.scaleBand().domain(xDomain).rangeRound(xRange), [xRange, xDomain]);

  return { x, y, xRange, xDomain, yRange, yDomain };
}

function useVerticalBarOptions(scales, nbSerie, options = {}) {
  return useMemo(() => {
    return {
      attributes: [
        { key: 'class', value: 'bar' },
        { key: 'y', value: ({ value }, i) => scales.yRange[1] - scales.y(value) },
        { key: 'height', value: ({ value }, i) => scales.y(value) },
        { key: 'width', value: -2 + scales.x.bandwidth() / nbSerie },
        {
          key: 'x',
          value: (d, i) => {
            return 1 + (scales.x.bandwidth() * i) / nbSerie;
          },
        },
        { key: 'rx', value: 0 },
        { key: 'ry', value: 0 },
      ],
      extras: [],
      styles: options.styles || [],
    };
  }, [scales.y, scales.x.bandwidth(), options.styles, scales.yRange]);
}

function useFlattenedDataSeries(dataSeries) {
  const [data, setData] = useState(
    dataSeries.series.reduce(
      (arr, serie) =>
        arr.concat(
          serie.data.map((d, index) => ({ value: 10 + Math.random() * 10, name: serie.name, descriptionIndex: index })),
        ),
      [],
    ),
  );

  const flattenedData = useMemo(() => {
    return dataSeries.series.reduce(
      (arr, serie) =>
        arr.concat(serie.data.map((d, index) => ({ value: d, name: serie.name, descriptionIndex: index }))),
      [],
    );
  }, [dataSeries.series]);

  useEffect(() => {
    const to = setTimeout(() => setData(flattenedData), dataSeries.series.length * 10);
    return () => {
      clearTimeout(to);
    };
  }, [flattenedData]);

  return data;
}

function useDataSeriesVerticalBarDraw({
  svg,
  flattenedDataSeries,
  descriptions,
  renderData,
  scales,
  options = {},
  tooltipID,
}) {
  const updateFromLeft = useRef(false);

  const { attributes: seriesAttributes } = useMemo(
    () => ({
      attributes: [{ key: 'class', value: 'series' }],
    }),
    [],
  );

  const {
    attributes: rectsAttributes,
    styles: rectsStyles,
    extras: rectsExtras,
  } = useVerticalBarOptions(scales, descriptions.length, options);

  const delayAnimation = useCallback(
    (d, index) => {
      const itemIndex = updateFromLeft.current ? index : flattenedDataSeries.length - 1 - index;
      const itemDelay = itemIndex * 4 * Math.sqrt(itemIndex);
      if (index === flattenedDataSeries.length - 1) {
        updateFromLeft.current = !updateFromLeft.current;
      }
      return itemDelay;
    },
    [flattenedDataSeries.length],
  );

  const [series] = ChartsHooks.useSvgElement({
    type: 'g',
    parent: svg,
    attributes: seriesAttributes,
  });

  const [bars] = ChartsHooks.useDataDrivenSvgElement({
    animationDelay: delayAnimation,
    attributes: rectsAttributes,
    data: flattenedDataSeries,
    duration: 400,
    extras: rectsExtras,
    origin: svg,
    parent: series,
    styles: rectsStyles,
    tooltipID,
    type: 'rect',
    renderData,
  });

  return [series, bars];
}

export const BarChartHooks = {
  useDataSeriesVerticalScale,
  useDataSeriesVerticalBarDraw,
  useFlattenedDataSeries,
};
