import React from 'react';
import { colors } from '@karnott/colors';

export const ParcelRentIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(8 9)">
        <path
          d="M322.995,1267.941a.712.712,0,0,1,.711.712v.6a.712.712,0,0,1-.711.711l-1.441.014,1.856-.014a.711.711,0,0,0,.5-.209s4.445-2.242,4.639-2.017a1.111,1.111,0,0,1-.1,1.5l-3.6,2.607a1.43,1.43,0,0,1-.942.417l-4.747.226-.757.756-2.775-2.775,2.1-2.1a1.427,1.427,0,0,1,1.01-.419Z"
          transform="translate(-315.632 -1261.329)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M326.725,1263.462l-7.385,2.127-1.976-1.557,7.385-2.127Z"
          transform="translate(-315.46 -1261.905)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (14.243 / 12.911)} height={size} viewBox="0 0 14.243 12.911">
      <g transform="translate(0.5 0.5)">
        <path
          d="M322.995,1267.941a.712.712,0,0,1,.711.712v.6a.712.712,0,0,1-.711.711l-1.441.014,1.856-.014a.711.711,0,0,0,.5-.209s4.445-2.242,4.639-2.017a1.111,1.111,0,0,1-.1,1.5l-3.6,2.607a1.43,1.43,0,0,1-.942.417l-4.747.226-.757.756-2.775-2.775,2.1-2.1a1.427,1.427,0,0,1,1.01-.419Z"
          transform="translate(-315.632 -1261.329)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M326.725,1263.462l-7.385,2.127-1.976-1.557,7.385-2.127Z"
          transform="translate(-315.46 -1261.905)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
