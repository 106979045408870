import React from 'react';
import { colors } from '@karnott/colors';

export const CancelIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(9 9)">
        <g transform="translate(1.865 1.702)">
          <line
            x1="8.679"
            y2="9.078"
            transform="translate(0 0)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <ellipse
          cx="6.323"
          cy="6.323"
          rx="6.323"
          ry="6.323"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 13.645 13.645">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(1.865 1.702)">
          <line
            x1="8.679"
            y2="9.078"
            transform="translate(0 0)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <ellipse
          cx="6.323"
          cy="6.323"
          rx="6.323"
          ry="6.323"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
