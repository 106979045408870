import React from 'react';
import { colors } from '@karnott/colors';

export const MailIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(6 9)">
        <g>
          <rect
            width="17.97"
            height="12.645"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g transform="translate(0)">
            <path
              d="M62.707,15.868l8.985,8.722,8.985-8.722"
              transform="translate(-62.707 -15.868)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (19.384 / 13.852)} height={size} viewBox="0 0 19.384 13.852">
      <g transform="translate(0.707 0.707)">
        <g>
          <rect
            width="17.97"
            height="12.645"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g transform="translate(0)">
            <path
              d="M62.707,15.868l8.985,8.722,8.985-8.722"
              transform="translate(-62.707 -15.868)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
