import React from 'react';
import { colors } from '@karnott/colors';

export const BroyIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(-994.339 -854.554)">
      <g>
        <circle
          cx="2.135"
          cy="2.135"
          r="2.135"
          transform="translate(1014.262 860.554)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <g>
        <path
          d="M1024.422,874.669a2.136,2.136,0,1,0-1.415,3.368"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <line
        x1="3.83"
        y1="9.696"
        transform="translate(1017.826 864.365)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1015.082,864.365l-1.519,6.724-5.5,2.973"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1010.743,878.548v-1.807a2.686,2.686,0,0,0-2.679-2.679h0a2.687,2.687,0,0,0-2.68,2.679v1.807"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1000.339,882.743h15.156l-1.023-4.07h-13.109Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1015.495,882.743V886h-15.156v-3.261"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M1031.839,886h-12.131l5.854-14.082h6.278Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
