import React from 'react';
import { colors } from '@karnott/colors';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';
import styled from 'styled-components';
import { CheckboxIcon, CheckboxOutlineBlank } from './mdCheckbox';

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${pixelSpacing('large')};
`;

const CheckboxContainer = styled.span`
  display: flex;
  align-items: center;
  padding-right: ${pixelSpacing('xSmall')};
  font-size: ${pixelSize()};
  color: ${props =>
    props.checked ? (props.checkboxColor ? props.checkboxColor : colors('green')) : colors('grey', 300)};
  position: relative;

  &:before {
    position: absolute;
    top: 8px;
    left: 4px;
    right: 8px;
    bottom: 4px;
    z-index: -1;
    display: block;
    content: '';
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: ${props => (props.checked ? colors('grey') : colors('grey', 'light'))};
  font-family: ${fontFamily()};
  font-size: ${pixelSize()};

  [type='checkbox'] {
    display: none;
  }
`;

function Checkbox({ checked, key, label, onChange, size = 20 }) {
  return (
    <CheckboxLabel htmlFor={label} {...{ checked, key }}>
      <input type="checkbox" {...{ id: label, checked, onChange: () => onChange(label) }} />

      <CheckboxContainer checked={checked}>
        {checked && <CheckboxIcon size={size} />}
        {!checked && <CheckboxOutlineBlank size={size} />}
      </CheckboxContainer>

      {label}
    </CheckboxLabel>
  );
}

function Checkboxes({ items, selection, onChange }) {
  return (
    <CheckboxesContainer>
      {items.map((item, index) => (
        <Checkbox key={`${item}-${index}`} checked={!!selection.includes(item)} label={item} onChange={onChange} />
      ))}
    </CheckboxesContainer>
  );
}

export { Checkbox, Checkboxes };
