import React from 'react';
import { colors } from '@karnott/colors';

export const FarmerIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(9 9)">
        <path
          d="M122.324,68.124a3.816,3.816,0,0,1,.152,1.092,3.686,3.686,0,0,1-.228,1.317,3.028,3.028,0,0,1-.641,1.032,2.871,2.871,0,0,1-.993.67,3.571,3.571,0,0,1-2.567,0,2.86,2.86,0,0,1-.995-.67,3,3,0,0,1-.641-1.032,3.926,3.926,0,0,1,0-2.634"
          transform="translate(-113.175 -63.919)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g transform="translate(0 8.165)">
          <path
            d="M121.284,71.652a6.318,6.318,0,0,1,1.045.3,5.633,5.633,0,0,1,1.964,1.254,5.478,5.478,0,0,1,1.216,1.941,5.26,5.26,0,0,1,.277.984h-12.3a5.009,5.009,0,0,1,.272-.984,5.5,5.5,0,0,1,1.233-1.941,5.583,5.583,0,0,1,1.948-1.254,6.359,6.359,0,0,1,.82-.253"
            transform="translate(-113.486 -71.652)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <line
          x2="11.067"
          transform="translate(0.621 4.214)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M122.336,68.566H116.3l1.215-4.235h3.609Z"
          transform="translate(-113.162 -64.331)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (13.299 / 13.645)} height={size} viewBox="0 0 13.299 13.645">
      <g transform="translate(0.5 0.5)">
        <path
          d="M122.324,68.124a3.816,3.816,0,0,1,.152,1.092,3.686,3.686,0,0,1-.228,1.317,3.028,3.028,0,0,1-.641,1.032,2.871,2.871,0,0,1-.993.67,3.571,3.571,0,0,1-2.567,0,2.86,2.86,0,0,1-.995-.67,3,3,0,0,1-.641-1.032,3.926,3.926,0,0,1,0-2.634"
          transform="translate(-113.175 -63.919)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g transform="translate(0 8.165)">
          <path
            d="M121.284,71.652a6.318,6.318,0,0,1,1.045.3,5.633,5.633,0,0,1,1.964,1.254,5.478,5.478,0,0,1,1.216,1.941,5.26,5.26,0,0,1,.277.984h-12.3a5.009,5.009,0,0,1,.272-.984,5.5,5.5,0,0,1,1.233-1.941,5.583,5.583,0,0,1,1.948-1.254,6.359,6.359,0,0,1,.82-.253"
            transform="translate(-113.486 -71.652)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <line
          x2="11.067"
          transform="translate(0.621 4.214)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M122.336,68.566H116.3l1.215-4.235h3.609Z"
          transform="translate(-113.162 -64.331)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
