import React from 'react';
import { colors } from '@karnott/colors';

export const PulveIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(-660.698 -853.233)">
      <g>
        <path
          d="M669.384,883.327l1.777-3.18,1.777,3.18"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M677.409,883.327l1.777-3.18,1.777,3.18"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M685.434,883.327l1.777-3.18,1.777,3.18"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M693.459,883.327l1.777-3.18,1.777,3.18"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <g>
        <g>
          <path
            d="M679.208,874.578,667.5,877.144a.937.937,0,0,0-.68,1.382l.634,1.139a.937.937,0,0,0,.819.481h29.857a.938.938,0,0,0,.819-.481l.633-1.139a.937.937,0,0,0-.68-1.382l-11.708-2.566"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <path
          d="M686.388,877.022h-6.379a.633.633,0,0,1-.632-.631l.632-12.527a.633.633,0,0,1,.631-.631h5.117a.633.633,0,0,1,.631.631l.631,12.527A.633.633,0,0,1,686.388,877.022Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <line
        x2="6.58"
        transform="translate(679.908 868.06)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
