import React from 'react';
import { colors } from '@karnott/colors';

export const BatteryIcon = ({
  color = '#000',
  size = 30,
  backgroundColor = colors('grey'),
  circled = false,
  batteryLevel = 0,
  batteryColor = colors('green'),
}) => {
  BatteryIcon.id += 1;
  return circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <defs>
        <linearGradient id={`battery-gradient-${BatteryIcon.id}`} x2="1">
          <stop stopColor={batteryColor} offset="0" />
          <stop stopColor={batteryColor} stopOpacity="1" offset={Math.max(0, batteryLevel - 1) / 100} />
          <stop stopColor="transparent" stopOpacity="0" offset={batteryLevel / 100} />
        </linearGradient>
      </defs>
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(8.245 10.756)">
        <g transform="translate(131.011 -1564) rotate(90)">
          <g transform="translate(329.003 325.862) rotate(-90)">
            <path
              d="M1.6,0H11.787a1.6,1.6,0,0,1,1.6,1.6V6.4a1.6,1.6,0,0,1-1.6,1.6H1.6A1.6,1.6,0,0,1,0,6.4V1.6A1.6,1.6,0,0,1,1.6,0Z"
              transform="translate(194.851 1234.997)"
              fill={`url(#battery-gradient-${BatteryIcon.id})`}
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <line x2="8" transform="translate(1564.5 120.5)" fill="none" stroke={color} strokeWidth="1" />
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (14.39 / 9)} height={size} viewBox="0 0 14.39 9">
      <defs>
        <linearGradient id={`battery-gradient-${BatteryIcon.id}`} x2="1">
          <stop stopColor={batteryColor} offset="0" />
          <stop stopColor={batteryColor} stopOpacity="1" offset={Math.max(0, batteryLevel - 1) / 100} />
          <stop stopColor="transparent" stopOpacity="0" offset={batteryLevel / 100} />
        </linearGradient>
      </defs>

      <g transform="translate(0.5 0.5)">
        <g transform="translate(131.011 -1564) rotate(90)">
          <g transform="translate(329.003 325.862) rotate(-90)">
            <path
              d="M1.6,0H11.787a1.6,1.6,0,0,1,1.6,1.6V6.4a1.6,1.6,0,0,1-1.6,1.6H1.6A1.6,1.6,0,0,1,0,6.4V1.6A1.6,1.6,0,0,1,1.6,0Z"
              transform="translate(194.851 1234.997)"
              fill={`url(#battery-gradient-${BatteryIcon.id})`}
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <line x2="8" transform="translate(1564.5 120.5)" fill="none" stroke={color} strokeWidth="1" />
        </g>
      </g>
    </svg>
  );
};

BatteryIcon.id = 1;
