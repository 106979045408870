export class AbstractService {
  constructor({ name, actioner, reducer, api, selectors }) {
    this.name = name;
    this.actioner = actioner;
    this.reducer = reducer;
    this.api = api;
    this.selectors = selectors;
  }
}

export class AbstractAuthenticatedService extends AbstractService {
  constructor({ name, actioner, reducer, api, selectors, authenticationService }) {
    super({ name, actioner, reducer, api, selectors });
    this.authenticationService = authenticationService;
  }
}
