import React from 'react';
import { colors } from '@karnott/colors';

export const RecoltIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(-911.393 -782.826)">
      <g>
        <path
          d="M945.156,809.559h2.026a.189.189,0,0,0,.189-.189v-2.014a.19.19,0,0,0-.189-.19H946.3l.663-1.938-1.845-3.554H939.9l-1.669-4.664"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="5.305"
          transform="translate(932.852 811.952)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g>
          <path
            d="M924.45,806.292l-7.712,4.255a.759.759,0,0,0,.415,1.4H922.7"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <ellipse
          cx="5.271"
          cy="5.409"
          rx="5.271"
          ry="5.409"
          transform="translate(922.505 805.084)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="2.448"
          cy="2.448"
          r="2.448"
          transform="translate(925.328 808.045)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="3.869"
          cy="3.971"
          rx="3.869"
          ry="3.971"
          transform="translate(938.157 807.961)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle cx="0.612" cy="0.612" r="0.612" transform="translate(941.415 811.32)" fill={color} />
        <line
          y2="7.781"
          transform="translate(928.927 797.272)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M945.121,801.674l.669-2.22,1.2-.877-.8-1.567H926.821a1.23,1.23,0,0,0-2.459,0h-3.085l3.173,9.282"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <line
        x2="2.875"
        transform="translate(947.518 808.199)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
