import React from 'react';
import { colors } from '@karnott/colors';

export const AreaIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(6 10)">
        <path
          d="M176.585,25.825H158.868l3.88-9.957h9.957Z"
          transform="translate(-158.868 -15.868)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y2="9.841"
          transform="translate(8.859)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x1="1.94"
          y2="9.841"
          transform="translate(4.389)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="1.94"
          y2="9.841"
          transform="translate(11.69)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="13.232"
          transform="translate(2.173 4.38)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (18.717 / 11.044)} height={size} viewBox="0 0 18.717 11.044">
      <g transform="translate(0.5 0.587)">
        <path
          d="M176.585,25.825H158.868l3.88-9.957h9.957Z"
          transform="translate(-158.868 -15.868)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y2="9.841"
          transform="translate(8.859)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x1="1.94"
          y2="9.841"
          transform="translate(4.389)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="1.94"
          y2="9.841"
          transform="translate(11.69)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="13.232"
          transform="translate(2.173 4.38)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
