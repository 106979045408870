import React from 'react';
import { colors } from '@karnott/colors';

export const TractorIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(-827.05 -783.464)">
      <g>
        <g>
          <g>
            <path
              d="M836.05,810.064a6.143,6.143,0,0,0,11.9,2.2"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M847.95,812.264a5.381,5.381,0,0,0,.4-2.2,6.15,6.15,0,1,0-12.3-.1"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <circle
              cx="2.5"
              cy="2.5"
              r="2.5"
              transform="translate(839.75 807.564)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <circle cx="0.6" cy="0.6" r="0.6" transform="translate(856.25 811.664)" fill={color} />
            <circle
              cx="4"
              cy="4"
              r="4"
              transform="translate(852.85 808.264)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <path
            d="M838.55,798.164h10.6"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x1="7.9"
            transform="translate(852.85 805.364)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x1="4.6"
            y2="2.5"
            transform="translate(846.55 802.464)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <path
          d="M851.15,802.464l-1.8-4.3,1.7-.9-.4-1.8h-11.5a.669.669,0,0,0-.7.6h0l.085,7.7v1.077"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M836.05,810.064a6.143,6.143,0,0,0,11.9,2.2h4.9a4,4,0,0,0,8,0h0l.1-8.1a1.685,1.685,0,0,0-1.7-1.7h-8"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
