import { colors } from '@karnott/colors';
import { Toggle } from '@karnott/form';
import { fontFamily, pixelSize, pixelSpacing, spacing } from '@karnott/theme';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DescriptionsHooks } from './effects';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: ${fontFamily()};
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: ${pixelSize('small')};
  color: ${colors('grey', 700)};
  margin: ${pixelSpacing('xSmall')} 0px;
`;

const Name = styled.div`
  display: flex;
  margin-left: ${pixelSpacing('small')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Total = styled.div`
  display: flex;
  color: ${colors('grey', 600)};
  margin-left: ${pixelSpacing('small')};
`;

const Dot = styled.div`
  width: ${pixelSpacing()};
  height: ${pixelSpacing()};
  border-radius: ${spacing() / 2}px;
  background-color: ${({ color }) => color};
`;

export function Descriptions({ descriptions, totalList, renderData }) {
  const { labels, colors: desciptionsColors } = DescriptionsHooks.useDescriptionsLists(descriptions);

  return (
    <Container>
      <Labels>
        {labels.map((description, id) => {
          return (
            <Label key={description}>
              <Dot color={desciptionsColors[id]} />
              <Name>{description}</Name>
              {totalList ? <Total>{renderData ? renderData(totalList[id]) : totalList[id]}</Total> : null}
            </Label>
          );
        })}
      </Labels>
    </Container>
  );
}

export { DescriptionsHooks } from './effects';
