import { colors } from '@karnott/colors';
import { PenIcon } from '@karnott/icons';
import { UIHooks } from '@karnott/hooks';
import { fontFamily, pixelSize, pixelSpacing, size as unitLessSize, spacing } from '@karnott/theme';
import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Effects as InputEffects } from './effects';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${fontFamily()};
  flex: ${({ full }) => (full ? 1 : 'none')};
`;
const Spacer = styled.div`
  width: ${pixelSpacing('small')};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  box-sizing: border-box;
  flex: ${({ full }) => (full ? 1 : 'none')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const InputContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
`;

const Input = styled.input`
  padding: 0;
  font-size: ${({ size }) => pixelSize(size)};
  color: ${({ color }) => colors(color)};
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  font-family: ${fontFamily()};
  overflow: hidden;
`;

const Title = styled.div`
  opacity: ${({ opened, hide }) => (opened || hide ? 0 : 1)};
  font-size: ${({ size }) => pixelSize(size)};
  margin: ${pixelSpacing('xSmall')} 0;
  padding-left: 1px;
  min-height: ${({ size }) => unitLessSize(size) + spacing('xSmall')}px;
  color: ${({ color, isHovered }) => colors(color, isHovered ? 400 : 500)};
`;

export function EditableText({
  Icon = PenIcon,
  size = 'regular',
  currentValue,
  disabled,
  isValidValue,
  full,
  placeholder,
  color = 'black',
  onSubmit,
}) {
  const [isOpen, open, close] = UIHooks.useOpenCloseState();
  const [containerRef, isHovered] = UIHooks.useHover();
  const [value, onValueChange] = InputEffects.useInputValue(currentValue);

  useEffect(() => {
    onValueChange({ target: { value: currentValue } });
  }, [currentValue, onValueChange]);

  const onBlurCB = useCallback(() => {
    if (isValidValue && !isValidValue(value)) {
      onValueChange({ target: { value: currentValue } });
      close();
    } else {
      onSubmit &&
        onSubmit(value).then(() => {
          close();
        });
    }
  }, [value, currentValue, isOpen, onValueChange, isValidValue, close, onSubmit]);

  return (
    <Container full={full}>
      <TitleContainer disabled={disabled} ref={containerRef} full={full} onClick={open}>
        <Title isHovered={isHovered} color={color} size={size} opened={isOpen}>
          {currentValue}
        </Title>
        {isOpen ? (
          <InputContainer>
            <Input
              color={color}
              size={size}
              autoFocus
              onBlur={onBlurCB}
              onChange={onValueChange}
              value={value}
              placeholder={placeholder}
            />
          </InputContainer>
        ) : null}
      </TitleContainer>
      <Spacer />
      <Icon size={unitLessSize(size)} color={!isHovered && !isOpen ? 'transparent' : colors('grey', 300)} />
    </Container>
  );
}
