import React from 'react';
import { colors } from '@karnott/colors';

export const AgregatedModeIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle data-name="Ellipse 391" cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(8 8)">
        <rect
          width="11.145"
          height="11.145"
          transform="translate(0 2.885)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M152.264,1271.178h-7.192v-7.192"
          transform="translate(-141.119 -1261.101)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          strokeDasharray="1 2"
        />
        <path
          d="M145.071,1263.985V1261.1h10.077v10.077h-2.885"
          transform="translate(-141.119 -1261.101)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 15.03 15.03">
      <g transform="translate(0.5 0.5)">
        <rect
          width="11.145"
          height="11.145"
          transform="translate(0 2.885)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M152.264,1271.178h-7.192v-7.192"
          transform="translate(-141.119 -1261.101)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          strokeDasharray="1 2"
        />
        <path
          d="M145.071,1263.985V1261.1h10.077v10.077h-2.885"
          transform="translate(-141.119 -1261.101)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
