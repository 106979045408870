import React from 'react';
import { colors } from '@karnott/colors';

export const PhoneIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(9 9)">
        <g>
          <g>
            <path
              d="M124.884,24.325l-2.56-1.707a.748.748,0,0,0-1,.162l-.746.959a.318.318,0,0,1-.408.087l-.142-.079a8.53,8.53,0,0,1-2.234-1.754,8.617,8.617,0,0,1-1.755-2.234l-.078-.142a.319.319,0,0,1,.085-.41l.958-.745a.748.748,0,0,0,.163-1l-1.708-2.56a.743.743,0,0,0-1-.225l-1.071.643a1.507,1.507,0,0,0-.688.893c-.385,1.405-.1,3.829,3.475,7.4,2.84,2.841,4.955,3.6,6.408,3.6a3.7,3.7,0,0,0,.99-.129,1.509,1.509,0,0,0,.894-.688l.643-1.07A.743.743,0,0,0,124.884,24.325Z"
              transform="translate(-112.569 -14.57)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (13.711 / 13.702)} height={size} viewBox="0 0 13.711 13.702">
      <g transform="translate(0.508 0.557)">
        <g>
          <g>
            <path
              id="Path_148"
              data-name="Path 148"
              d="M124.884,24.325l-2.56-1.707a.748.748,0,0,0-1,.162l-.746.959a.318.318,0,0,1-.408.087l-.142-.079a8.53,8.53,0,0,1-2.234-1.754,8.617,8.617,0,0,1-1.755-2.234l-.078-.142a.319.319,0,0,1,.085-.41l.958-.745a.748.748,0,0,0,.163-1l-1.708-2.56a.743.743,0,0,0-1-.225l-1.071.643a1.507,1.507,0,0,0-.688.893c-.385,1.405-.1,3.829,3.475,7.4,2.84,2.841,4.955,3.6,6.408,3.6a3.7,3.7,0,0,0,.99-.129,1.509,1.509,0,0,0,.894-.688l.643-1.07A.743.743,0,0,0,124.884,24.325Z"
              transform="translate(-112.569 -14.57)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
