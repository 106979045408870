import React from 'react';
import { colors } from '@karnott/colors';

export const FarmhouseIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(8 7)">
        <path
          d="M7.29-2.43V8.505H-7.29V-2.3085L0-7.29ZM-1.215-1.215L1.215-1.215M1.215-1.215L1.215-3.645L-1.215-3.645L-1.215-1.215L1.215-1.215M4.86 8.505L4.86 1.215L-4.86 1.215L-4.86 8.505M4.86 1.215L-4.86 8.505M-4.86 1.215L4.86 8.505"
          transform="translate(7 8)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 13.645 15">
      <g>
        <path
          d="M76 68v9H64V68.068l6-4.068ZM69 69L71 69M71 69L71 67l-2 0l0 2L71 69M74 77L74 71L66 71L66 77M74 71L66 77M66 71L74 77"
          transform="translate(-63 -63)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
