import React from 'react';
import { colors } from '@karnott/colors';

export const ClusterIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(9 9)">
        <path
          d="M76.976,68.068v8.908H64.331V68.068l6.323-3.737Z"
          transform="translate(-64.331 -64.331)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 13.645 13.645">
      <g transform="translate(0.5 0.5)">
        <path
          d="M76.976,68.068v8.908H64.331V68.068l6.323-3.737Z"
          transform="translate(-64.331 -64.331)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
