import React from 'react';
import { colors } from '@karnott/colors';

export const SerialNumberIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(7 10)">
        <g transform="translate(-58.91 -1278.053)">
          <g>
            <path
              d="M75.051,1280.088v-2.035H73.213"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M73.213,1288.317h1.838v-2.035"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M58.91,1286.282v2.035h1.838"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M60.747,1278.053H58.91v2.035"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <line
            x2="16.141"
            transform="translate(58.91 1283.185)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            width="8.522"
            height="5.966"
            transform="translate(62.719 1280.088)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (17.141 / 11.264)} height={size} viewBox="0 0 17.141 11.264">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(-58.91 -1278.053)">
          <g>
            <path
              d="M75.051,1280.088v-2.035H73.213"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M73.213,1288.317h1.838v-2.035"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M58.91,1286.282v2.035h1.838"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M60.747,1278.053H58.91v2.035"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <line
            x2="16.141"
            transform="translate(58.91 1283.185)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            width="8.522"
            height="5.966"
            transform="translate(62.719 1280.088)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
