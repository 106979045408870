import React from 'react';
import { colors } from '@karnott/colors';

export const ShareIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(9 9)">
        <line
          y1="2.4"
          x2="4.387"
          transform="translate(3.405 2.686)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x1="4.386"
          y1="2.4"
          transform="translate(3.406 6.824)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.818"
          cy="1.818"
          r="1.818"
          transform="translate(7.562 8.274)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.818"
          cy="1.818"
          r="1.818"
          transform="translate(7.562)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.818"
          cy="1.818"
          r="1.818"
          transform="translate(0 4.137)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (12.198 / 12.91)} height={size} viewBox="0 0 12.198 12.91">
      <g transform="translate(0.5 0.5)">
        <line
          y1="2.4"
          x2="4.387"
          transform="translate(3.405 2.686)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x1="4.386"
          y1="2.4"
          transform="translate(3.406 6.824)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.818"
          cy="1.818"
          r="1.818"
          transform="translate(7.562 8.274)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.818"
          cy="1.818"
          r="1.818"
          transform="translate(7.562)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.818"
          cy="1.818"
          r="1.818"
          transform="translate(0 4.137)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
