import { AbstractReducerReduceFunctionOverride } from './errors';

export default class AbstractReducer {
  constructor({ initialState }) {
    this.initialState = initialState;
  }

  setInitialState(is) {
    this.initialState = is;
  }

  reduce() {
    throw new AbstractReducerReduceFunctionOverride();
  }
}

export class AbstractListReducer extends AbstractReducer {
  constructor({ actioner, initialState = { list: null, listLoading: false } }) {
    super({ initialState });
    this._actioner = actioner;
  }

  reduce(state = null, action) {
    if (state === null) state = this.initialState;
    switch (action.type) {
      case this._actioner.actionTypes.SET:
        return { ...state, list: action.payload, listLoading: false };
      case this._actioner.actionTypes.SET_LOADING:
        return { ...state, listLoading: action.payload };
      default:
        return state;
    }
  }
}
