import { colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { fontFamily, pixelSize, pixelSpacing, spacing } from '@karnott/theme';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ChartsHooks } from '../effects';
import { Descriptions } from '../Filters';
import { CircularChartHooks } from './effects';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  background-color: ${({ background }) => background};
  font-family: ${fontFamily()};
  font-weight: 500;
  padding: ${pixelSpacing()};
  border-radius: ${pixelSpacing('xSmall')};
  ${({ elevation = '' }) => elevation}
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${pixelSpacing('small')};
`;

const ChartContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const Chart = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  min-height: ${spacing('xLarge') * 1.5}px;
  margin-right: ${pixelSpacing()};
  position: relative;
  > svg {
    overflow: visible;
  }
`;

export function CircularChart({
  dataSerie = { name: '', data: [], meta: {} },
  descriptions = [],
  background = colors('white'),
  renderTitle,
  withTooltip,
}) {
  const elevation = UIHooks.useElevation({ elevated: true });
  const chart = useRef(null);
  const { attributes: chartAttributes, styles: chartStyles } = useMemo(
    () => ({
      attributes: [{ key: 'class', value: 'circular-chart' }],
      styles: [{ key: 'background-color', value: 'transparent' }],
    }),
    [],
  );
  const [svg, width, height] = ChartsHooks.useSvg(chart, chartAttributes, chartStyles);
  const options = useMemo(
    () => ({ radius: Math.min(width, height) / 2, width, height, tapeWidth: 14 }),
    [width, height],
  );

  const totalList = ChartsHooks.getDataSeriesTotal({ descriptions, series: [dataSerie] });
  const tooltipID = useMemo(() => (withTooltip ? `circular-tooltip-${Math.floor(Math.random() * 1000)}` : null), []);
  CircularChartHooks.useDataSerieCircularDraw(svg, dataSerie, descriptions, options, tooltipID);

  return (
    <Container {...{ background, elevation }}>
      {renderTitle ? <TitleContainer> {renderTitle()} </TitleContainer> : null}
      <ChartContainer>
        <Chart ref={chart}>{tooltipID ? <CircularChartTooltip id={tooltipID} className="hidden" /> : null}</Chart>
        <Descriptions descriptions={descriptions} totalList={totalList} renderData={dataSerie.renderData} />
      </ChartContainer>
    </Container>
  );
}

const CircularChartTooltip = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  height: 24px;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  pointer-events: none;
  color: ${colors('white')};
  font-family: ${fontFamily()};
  font-size: ${pixelSize('small')};
  z-index: 9999;
  &.hidden {
    display: none;
  }
`;

export { CircularChartHooks } from './effects';
