import { useCallback, useState } from 'react';

function useSwitchValue(initialValue = false) {
  const [value, setValue] = useState(initialValue);
  const toggleValue = useCallback(() => {
    setValue(!value);
  }, [value, setValue]);
  return [value, toggleValue, setValue];
}

export const ToggleHooks = {
  useSwitchValue,
};
