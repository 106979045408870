import React from 'react';
import { colors } from '@karnott/colors';

export const EjectIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(11 12)">
        <g>
          <g>
            <path
              d="M314.965,115.592l3.8,3.8h-7.608l3.8-3.8"
              transform="translate(-311.161 -115.592)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <line
          x2="7.534"
          transform="translate(0.037 6.737)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (8.608 / 7.944)} height={size} viewBox="0 0 8.608 7.944">
      <g transform="translate(0.5 0.707)">
        <g>
          <g>
            <path
              d="M314.965,115.592l3.8,3.8h-7.608l3.8-3.8"
              transform="translate(-311.161 -115.592)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <line
          x2="7.534"
          transform="translate(0.037 6.737)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
