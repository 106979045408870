import { elevation } from '@karnott/theme';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

function useHover(disabled) {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseEnter = useCallback(() => !disabled && setValue(true), [disabled]);
  const handleMouseLeave = useCallback(() => !disabled && setValue(false), [disabled]);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        node.removeEventListener('mouseover', handleMouseEnter);
        node.removeEventListener('mouseout', handleMouseLeave);
      };
    }
  }, [ref.current]);

  return [ref, value];
}

function useOpenCloseState(isOpen) {
  const [opened, setOpened] = useState(isOpen);

  const open = useCallback(() => setOpened(true), [setOpened]);
  const close = useCallback(() => setOpened(false), [setOpened]);
  const toggle = useCallback(() => setOpened(!opened), [opened, setOpened]);

  return [opened, open, close, toggle];
}

function useScrollToTopWhenSwitcherIsOn(switcher, onSwitch) {
  const scrollableRef = useRef();
  useEffect(() => {
    if (scrollableRef.current && switcher) {
      scrollableRef.current.scrollTop(0, { behavior: 'smooth' });
      onSwitch && onSwitch();
    }
  }, [onSwitch, scrollableRef, switcher, behavior]);

  return scrollableRef;
}

function useContainerSize(container, onResize) {
  const [[width, height], setWidthHeight] = useState([0, 0]);

  useEffect(() => {
    if (!container.current) return () => {};
    function resize() {
      const width = container.current.clientWidth;
      const height = container.current.clientHeight;
      onResize && onResize();
      setWidthHeight([width, height]);
    }
    const to = setTimeout(resize, 100);
    window.addEventListener('resize', resize);
    return () => {
      to && clearTimeout(to);
      window.removeEventListener('resize', resize);
    };
  }, [container, onResize]);
  return [width, height];
}

function useDraggableContainer(onMove) {
  const container = useRef();
  const isHoldingDown = useRef(false);

  const onMouseDown = useCallback(() => {
    isHoldingDown.current = true;
  }, [isHoldingDown]);

  const onMouseUp = useCallback(() => {
    isHoldingDown.current = false;
  }, [isHoldingDown]);

  const onMouseMove = useCallback(
    event => {
      event.preventDefault();
      if (isHoldingDown.current) {
        onMove && onMove({ dx: event.movementX, dy: event.movementY });
      }
    },
    [isHoldingDown, onMove],
  );

  useEffect(() => {
    if (!container || !container.current) return () => {};
    container.current.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
    return () => {
      container.current.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [container, onMouseDown, onMouseUp, onMouseMove]);

  return [container];
}

function useElevation({ added, elevated }) {
  return useMemo(() => elevation({ added, elevated }), [added, elevated]);
}

export const UIHooks = {
  useContainerSize,
  useDraggableContainer,
  useElevation,
  useHover,
  useOpenCloseState,
  useScrollToTopWhenSwitcherIsOn,
};
