import { colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { fontFamily, pixelSpacing } from '@karnott/theme';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { TracksFilter, TracksFilterHooks } from '../Filters';
import { ScoreLine } from './components/ScoreLine';
import { PerformanceScoreboardHooks } from './effects';
import { ScoreHeads } from './components/ScoreHeads';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  background-color: ${({ background }) => background};
  font-family: ${fontFamily()};
  font-weight: 500;
  padding: ${pixelSpacing()};
  border-radius: ${pixelSpacing('xSmall')};
  ${({ elevation = '' }) => elevation}
`;

const Header = styled.div`
  display: flex;
  margin-bottom: ${pixelSpacing()};
`;

const Series = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
`;

const Recap = styled.div`
  display: flex;
  flex: 1;
`;

const Bars = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 2;
`;

function PerformanceScoreboard({
  dataSeries = {
    descriptions: [],
    series: [],
  },
  background = colors('white'),
  renderRecap,
}) {
  const bars = useRef(null);
  const [width, height] = UIHooks.useContainerSize(bars);
  const elevation = UIHooks.useElevation({ elevated: true });
  const [filteredDataSeries, tracksFilter, toggleTrackFilter] = TracksFilterHooks.useTracksFilter(dataSeries);

  const displayToggle = useMemo(() => dataSeries.descriptions?.length > 1, [dataSeries]);

  const series = useMemo(() => {
    return filteredDataSeries.series;
  }, [filteredDataSeries]);

  const seriesColors = useMemo(() => {
    return filteredDataSeries.descriptions.map(({ color }) => color || colors('black', 'light'));
  }, [filteredDataSeries]);
  const [scales] = PerformanceScoreboardHooks.useDataSeriesHorizontalScales(
    width,
    height / (series.length || 1),
    filteredDataSeries,
  );
  const [total] = PerformanceScoreboardHooks.useDataSerieTotal(filteredDataSeries);

  return (
    <Container {...{ background, elevation }}>
      <Header>
        {renderRecap ? <Recap> {renderRecap({ total })} </Recap> : null}
        {displayToggle ? (
          <TracksFilter
            descriptions={dataSeries.descriptions}
            filters={tracksFilter}
            toggleTrackVisibilty={toggleTrackFilter}
          />
        ) : null}
      </Header>
      <Series>
        <ScoreHeads dataSeries={series} />
        <Bars ref={bars}>
          {series.map(serie => {
            return (
              <React.Fragment key={serie.name}>
                <ScoreLine dataSerie={serie} scales={scales} seriesColors={seriesColors} />
              </React.Fragment>
            );
          })}
        </Bars>
      </Series>
    </Container>
  );
}
export { PerformanceScoreboard, PerformanceScoreboardHooks };
