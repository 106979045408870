import React from 'react';
import { colors } from '@karnott/colors';

export const ImplementIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <g transform="translate(-1770 -205)">
        <circle cx="15" cy="15" r="15" transform="translate(1770 205)" fill={backgroundColor} />
        <g transform="translate(1779 215)">
          <g transform="translate(-334.276 -1279.479)">
            <g>
              <g>
                <path
                  d="M334.276,1287.1a3.206,3.206,0,0,0,6.211,1.148"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M340.487,1288.247a2.817,2.817,0,0,0,.208-1.148,3.21,3.21,0,1,0-6.419-.052"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <circle
                  cx="2.088"
                  cy="2.088"
                  r="2.088"
                  transform="translate(343.044 1286.16)"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
              <line
                x1="2.806"
                y2="1.326"
                transform="translate(339.351 1283.132)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <path
              d="M342.157,1283.132l-.94-3.653h-5.323a.349.349,0,0,0-.365.313h0l.052,4.419"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M334.276,1287.1a3.206,3.206,0,0,0,6.211,1.148h2.557a2.087,2.087,0,1,0,4.175,0h0l.052-4.227a.88.88,0,0,0-.887-.888h-4.175"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (13.995 / 11.856)} height={size} viewBox="0 0 13.995 11.856">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(-334.276 -1279.479)">
          <g>
            <g>
              <path
                d="M334.276,1287.1a3.206,3.206,0,0,0,6.211,1.148"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                d="M340.487,1288.247a2.817,2.817,0,0,0,.208-1.148,3.21,3.21,0,1,0-6.419-.052"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <circle
                cx="2.088"
                cy="2.088"
                r="2.088"
                transform="translate(343.044 1286.16)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <line
              x1="2.806"
              y2="1.326"
              transform="translate(339.351 1283.132)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <path
            d="M342.157,1283.132l-.94-3.653h-5.323a.349.349,0,0,0-.365.313h0l.052,4.419"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M334.276,1287.1a3.206,3.206,0,0,0,6.211,1.148h2.557a2.087,2.087,0,1,0,4.175,0h0l.052-4.227a.88.88,0,0,0-.887-.888h-4.175"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
