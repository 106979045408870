import React from 'react';
import { colors } from '@karnott/colors';

export const SaveIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(9 9)">
        <g transform="translate(-229.854 -253.736)">
          <g transform="translate(233.27 258.224)">
            <line
              x1="3.668"
              y2="3.668"
              transform="translate(2.146)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="2.146"
              y1="2.146"
              transform="translate(0 1.522)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <ellipse
            cx="6.323"
            cy="6.323"
            rx="6.323"
            ry="6.323"
            transform="translate(229.854 253.736)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 13.645 13.645">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(-229.854 -253.736)">
          <g transform="translate(233.27 258.224)">
            <line
              x1="3.668"
              y2="3.668"
              transform="translate(2.146)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="2.146"
              y1="2.146"
              transform="translate(0 1.522)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <ellipse
            cx="6.323"
            cy="6.323"
            rx="6.323"
            ry="6.323"
            transform="translate(229.854 253.736)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
