import { colors } from '@karnott/colors';
import { fontFamily, pixelSize, pixelSpacing, size } from '@karnott/theme';
import * as d3 from 'd3';
import { useMemo } from 'react';
import { ChartsHooks } from '../effects';

function getHorizontalScale(nbSeries, values, width, height) {
  const xRange = [0, width];
  const xDomain = [0, d3.extent(values)[1]];
  const yRange = [height, 0];
  const yDomain = [];
  for (let i = 0; i < nbSeries; i++) yDomain.push(i);
  const x = d3.scaleLinear().domain(xDomain).rangeRound(xRange);
  const y = d3.scaleBand().domain(yDomain).rangeRound(yRange);
  return { x, y, xRange, xDomain, yRange, yDomain };
}

function getSeriesAsLists(dataSeries) {
  if (!dataSeries?.series?.length) return [];
  let lists = dataSeries?.series[0].data.map(() => []);
  dataSeries?.series.forEach((dataSerie, i) => {
    lists = lists.map((l, i) => {
      return l.concat([dataSerie.data[i]]);
    });
  });
  return lists;
}

function useDataSeriesHorizontalScales(width, height, dataSeries, margin = {}) {
  const margedWidth = useMemo(() => width - (margin.left || 0) - (margin.right || 0), [width]);
  const margedHeight = useMemo(() => height - (margin.top || 0) - (margin.bottom || 0), [height]);
  const valueLists = useMemo(() => getSeriesAsLists(dataSeries), [dataSeries]);

  const scalesForLists = useMemo(() => {
    return valueLists.map(l => getHorizontalScale(valueLists.length, l, margedWidth, margedHeight));
  }, [valueLists, margedWidth, margedHeight]);
  return [scalesForLists, valueLists];
}

function useDataSerieTotal(dataSeries) {
  const total = useMemo(() => {
    return dataSeries.series.reduce((tot, serie) => tot + serie.data.reduce((stot, value) => stot + value, 0), 0);
  }, [dataSeries]);

  return [total];
}

function useBarOptions(scales, options = {}) {
  return useMemo(() => {
    return {
      attributes: [
        { key: 'class', value: 'bar' },
        {
          key: 'y',
          value: (d, i) => {
            return scales[i].y(i);
          },
        },
        {
          key: 'height',
          value: (v, i) => {
            return scales[i].y.bandwidth() - 2;
          },
        },
        { key: 'x', value: 0 },
        { key: 'rx', value: 3 },
        { key: 'ry', value: 3 },
        {
          key: 'width',
          value: (d, i) => {
            return scales[i].x(d);
          },
        },
      ],
      extras: [],
      styles: options.styles || [],
    };
  }, [scales, options.styles]);
}

function useTextOptions(scales) {
  return useMemo(
    () => ({
      attributes: [
        { key: 'class', value: 'text' },
        { key: 'font-size', value: pixelSize('xSmall') },
        { key: 'fill', value: colors('white') },
        { key: 'font-family', value: fontFamily() },
        {
          key: 'y',
          value: (d, i) => {
            return scales[i].y(i) + (scales[i].y.bandwidth() + size('xSmall') / 2) / 2;
          },
        },
        { key: 'x', value: pixelSpacing('xSmall') },
      ],
      extras: [{ key: 'text', value: d => d.toFixed(2) }],
      styles: [{ key: 'z-index', value: 10 }],
    }),
    [scales],
  );
}

function useValuesHorizontalBarDraw(svg, data, scales, options = {}) {
  const { attributes: serieAttributes } = useMemo(
    () => ({
      attributes: [{ key: 'class', value: 'serie' }],
    }),
    [],
  );

  const { attributes: rectsAttributes, styles: rectsStyles, extras: rectsExtras } = useBarOptions(scales, options);
  const { attributes: textsAttributes, styles: textsStyles, extras: textsExtras } = useTextOptions(scales);

  const [serie] = ChartsHooks.useSvgElement({
    type: 'g',
    parent: svg,
    attributes: serieAttributes,
  });

  const [bars] = ChartsHooks.useDataDrivenSvgElement({
    type: 'rect',
    parent: serie,
    data: data,
    attributes: rectsAttributes,
    styles: rectsStyles,
    extras: rectsExtras,
    withOverBehavior: false,
  });

  const [texts] = ChartsHooks.useDataDrivenSvgElement({
    type: 'text',
    parent: serie,
    data: data,
    attributes: textsAttributes,
    styles: textsStyles,
    extras: textsExtras,
    withOverBehavior: false,
  });

  return [serie, bars, texts];
}

function useDataSerieHorizontalBarDraw(svg, dataSerie, scales, options = {}) {
  const values = useMemo(() => dataSerie.data, [dataSerie]);
  return useValuesHorizontalBarDraw(svg, values, scales, options);
}

export const PerformanceScoreboardHooks = {
  useBarOptions,
  useTextOptions,
  useDataSerieHorizontalBarDraw,
  useDataSerieTotal,
  useDataSeriesHorizontalScales,
};
