import React from 'react';
import { colors } from '@karnott/colors';

export const DistanceIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(8 11)">
        <line
          x1="7.391"
          transform="translate(3.479 3.801)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g transform="translate(-526.619 -1263.68)">
          <g>
            <line
              x1="1.36"
              y1="1.36"
              transform="translate(536.553 1266.121)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="1.36"
              y2="1.36"
              transform="translate(536.553 1267.481)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <g>
            <line
              x2="1.36"
              y2="1.36"
              transform="translate(529.673 1267.481)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              y1="1.36"
              x2="1.36"
              transform="translate(529.673 1266.121)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <g transform="translate(-526.619 -1263.68)">
          <line
            y1="7.602"
            transform="translate(540.967 1263.68)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            y1="7.602"
            transform="translate(526.619 1263.68)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (15.348 / 8.602)} height={size} viewBox="0 0 15.348 8.602">
      <g transform="translate(0.5 0.5)">
        <line
          x1="7.391"
          transform="translate(3.479 3.801)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g transform="translate(-526.619 -1263.68)">
          <g>
            <line
              x1="1.36"
              y1="1.36"
              transform="translate(536.553 1266.121)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="1.36"
              y2="1.36"
              transform="translate(536.553 1267.481)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <g>
            <line
              x2="1.36"
              y2="1.36"
              transform="translate(529.673 1267.481)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              y1="1.36"
              x2="1.36"
              transform="translate(529.673 1266.121)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <g transform="translate(-526.619 -1263.68)">
          <line
            y1="7.602"
            transform="translate(540.967 1263.68)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            y1="7.602"
            transform="translate(526.619 1263.68)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
