import React from 'react';
import { colors } from '@karnott/colors';

export const LockIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(10 9)">
        <line
          y2="1.64"
          transform="translate(4.648 8.201)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <rect
          width="9.295"
          height="7.655"
          transform="translate(0 4.921)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M213.949,17.627a3.007,3.007,0,0,1,6.014,0v1.914h-6.014Z"
          transform="translate(-212.308 -14.62)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (10.295 / 13.576)} height={size} viewBox="0 0 10.295 13.576">
      <g transform="translate(0.5 0.5)">
        <line
          y2="1.64"
          transform="translate(4.648 8.201)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <rect
          width="9.295"
          height="7.655"
          transform="translate(0 4.921)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M213.949,17.627a3.007,3.007,0,0,1,6.014,0v1.914h-6.014Z"
          transform="translate(-212.308 -14.62)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
