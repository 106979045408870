import React from 'react';
import { colors } from '@karnott/colors';

export const HelpIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(-189.51 -1188.406)">
        <path
          d="M204.308,1199.62a3.921,3.921,0,0,0-1.877.495l-.378-1.048a5.1,5.1,0,0,1,1.114-.422,5.238,5.238,0,0,1,1.317-.16,3.5,3.5,0,0,1,1.359.226,2.4,2.4,0,0,1,.851.576,1.931,1.931,0,0,1,.444.777,2.971,2.971,0,0,1,.125.822,1.974,1.974,0,0,1-.19.88,3.672,3.672,0,0,1-.464.72,6.326,6.326,0,0,1-.611.64,6.645,6.645,0,0,0-.612.633,3.5,3.5,0,0,0-.466.713,1.94,1.94,0,0,0-.189.866v.161a.815.815,0,0,0,.015.159H203.61a3.111,3.111,0,0,1-.058-.611,2.068,2.068,0,0,1,.167-.844,3.237,3.237,0,0,1,.429-.706,6.672,6.672,0,0,1,.553-.611c.194-.189.378-.379.553-.573a3.012,3.012,0,0,0,.429-.612,1.48,1.48,0,0,0,.169-.7,1.339,1.339,0,0,0-.394-1A1.573,1.573,0,0,0,204.308,1199.62Zm.917,8.4a.982.982,0,0,1-.262.684,1.019,1.019,0,0,1-1.426,0,1.027,1.027,0,0,1,0-1.368,1.019,1.019,0,0,1,1.426,0A.984.984,0,0,1,205.225,1208.015Z"
          fill={color}
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (10.505 / 5.21)} height={size} viewBox="0 0 5.21 10.505">
      <g transform="translate(-202.053 -1198.485)">
        <path
          d="M204.308,1199.62a3.921,3.921,0,0,0-1.877.495l-.378-1.048a5.1,5.1,0,0,1,1.114-.422,5.238,5.238,0,0,1,1.317-.16,3.5,3.5,0,0,1,1.359.226,2.4,2.4,0,0,1,.851.576,1.931,1.931,0,0,1,.444.777,2.971,2.971,0,0,1,.125.822,1.974,1.974,0,0,1-.19.88,3.672,3.672,0,0,1-.464.72,6.326,6.326,0,0,1-.611.64,6.645,6.645,0,0,0-.612.633,3.5,3.5,0,0,0-.466.713,1.94,1.94,0,0,0-.189.866v.161a.815.815,0,0,0,.015.159H203.61a3.111,3.111,0,0,1-.058-.611,2.068,2.068,0,0,1,.167-.844,3.237,3.237,0,0,1,.429-.706,6.672,6.672,0,0,1,.553-.611c.194-.189.378-.379.553-.573a3.012,3.012,0,0,0,.429-.612,1.48,1.48,0,0,0,.169-.7,1.339,1.339,0,0,0-.394-1A1.573,1.573,0,0,0,204.308,1199.62Zm.917,8.4a.982.982,0,0,1-.262.684,1.019,1.019,0,0,1-1.426,0,1.027,1.027,0,0,1,0-1.368,1.019,1.019,0,0,1,1.426,0A.984.984,0,0,1,205.225,1208.015Z"
          fill={color}
        />
      </g>
    </svg>
  );
