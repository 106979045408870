import React from 'react';
import { colors } from '@karnott/colors';

export const HayMakingIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(-739.396 -850.465)">
      <g>
        <g>
          <g>
            <g>
              <ellipse
                cx="2.559"
                cy="1.425"
                rx="2.559"
                ry="1.425"
                transform="translate(766.825 875.399)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <g>
                <path
                  d="M761.722,875.5a9.6,9.6,0,0,1,5.991.246"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M762.283,878.906c.664-1.19,2.477-2.006,4.55-2.185"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M767,881.094c-1.042-1.1-.8-2.4.444-3.338"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M773.116,880.777c-2.05-.354-3.48-1.3-3.879-2.391"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M777.045,878.148a9.6,9.6,0,0,1-5.99-.245"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M776.488,874.747c-.665,1.191-2.48,2.007-4.555,2.186"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M771.767,872.559c1.042,1.1.795,2.394-.442,3.337"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M765.651,872.871c2.138.37,3.6,1.379,3.924,2.533"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </g>
          </g>
          <path
            d="M774.876,865.465l-5.493,4v5.862"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g>
          <g>
            <g>
              <ellipse
                cx="2.559"
                cy="1.425"
                rx="2.559"
                ry="1.425"
                transform="translate(748.498 875.399)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <g>
                <path
                  d="M743.4,875.5a9.594,9.594,0,0,1,5.99.246"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M743.957,878.906c.664-1.19,2.477-2.006,4.55-2.185"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M748.678,881.094c-1.043-1.1-.795-2.4.443-3.338"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M754.79,880.777c-2.059-.356-3.493-1.3-3.885-2.406"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M758.718,878.148a9.6,9.6,0,0,1-5.989-.245"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M758.162,874.747c-.665,1.191-2.48,2.007-4.555,2.186"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M753.441,872.559c1.042,1.1.8,2.394-.443,3.337"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M747.324,872.871c2.138.37,3.6,1.379,3.925,2.533"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </g>
          </g>
          <path
            d="M756.55,865.465l-5.493,4v5.862"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
      <line
        x1="30.911"
        transform="translate(747.709 865.465)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
