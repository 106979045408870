import React from 'react';
import { colors } from '@karnott/colors';

export const LinkIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(9 9)">
        <path
          d="M170.478,74.914l-.3.3a4.5,4.5,0,0,1-6.366,0h0a4.5,4.5,0,0,1,0-6.366l.3-.3"
          transform="translate(-162.489 -63.891)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M166.548,66.111l.3-.3a4.5,4.5,0,0,1,6.366,0h0a4.5,4.5,0,0,1,0,6.366l-.3.3"
          transform="translate(-161.891 -64.489)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x1="2.857"
          y2="2.857"
          transform="translate(4.894 4.894)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 13.646 13.646">
      <g transform="translate(0.5 0.5)">
        <path
          d="M170.478,74.914l-.3.3a4.5,4.5,0,0,1-6.366,0h0a4.5,4.5,0,0,1,0-6.366l.3-.3"
          transform="translate(-162.489 -63.891)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M166.548,66.111l.3-.3a4.5,4.5,0,0,1,6.366,0h0a4.5,4.5,0,0,1,0,6.366l-.3.3"
          transform="translate(-161.891 -64.489)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x1="2.857"
          y2="2.857"
          transform="translate(4.894 4.894)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
