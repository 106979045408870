import React from 'react';
import { colors } from '@karnott/colors';

export const DownloadIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(11 9)">
        <g>
          <line
            y1="8.946"
            transform="translate(3.804)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g transform="translate(0 5.748)">
            <line
              y1="3.804"
              x2="3.804"
              transform="translate(3.804)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="3.804"
              y1="3.804"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <line
          x2="7.534"
          transform="translate(0.037 12.645)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (9.022 / 13.645)} height={size} viewBox="0 0 9.022 13.645">
      <g transform="translate(0.707 0.5)">
        <g>
          <line
            y1="8.946"
            transform="translate(3.804)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g transform="translate(0 5.748)">
            <line
              y1="3.804"
              x2="3.804"
              transform="translate(3.804)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="3.804"
              y1="3.804"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <line
          x2="7.534"
          transform="translate(0.037 12.645)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
