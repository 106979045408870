import React from 'react';
import { colors } from '@karnott/colors';

export const KarnottIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(7 11)">
        <g transform="translate(-1195 -99.653)">
          <path
            d="M1502.792,291.6l-1.478,1.729a.792.792,0,0,1-.6.276h-12.36a.79.79,0,0,1-.6-.276l-1.478-1.729a.792.792,0,0,1-.19-.513v-2.813a.792.792,0,0,1,.19-.513l1.478-1.729a.79.79,0,0,1,.6-.277h12.36a.792.792,0,0,1,.6.277l1.478,1.729a.79.79,0,0,1,.189.513v2.813A.79.79,0,0,1,1502.792,291.6Z"
            transform="translate(-291.085 -186.103)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <circle cx="0.5" cy="0.5" r="0.5" transform="translate(1208 102.133)" fill={color} />
          <circle cx="0.5" cy="0.5" r="0.5" transform="translate(1206 102.133)" fill={color} />
          <circle cx="0.5" cy="0.5" r="0.5" transform="translate(1204 102.133)" fill={color} />
        </g>
        <line
          x1="16.478"
          transform="translate(0.262 5.645)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (17.897 / 8.851)} height={size} viewBox="0 0 17.897 8.851">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(-1195 -99.653)">
          <path
            d="M1502.792,291.6l-1.478,1.729a.792.792,0,0,1-.6.276h-12.36a.79.79,0,0,1-.6-.276l-1.478-1.729a.792.792,0,0,1-.19-.513v-2.813a.792.792,0,0,1,.19-.513l1.478-1.729a.79.79,0,0,1,.6-.277h12.36a.792.792,0,0,1,.6.277l1.478,1.729a.79.79,0,0,1,.189.513v2.813A.79.79,0,0,1,1502.792,291.6Z"
            transform="translate(-291.085 -186.103)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <circle cx="0.5" cy="0.5" r="0.5" transform="translate(1208 102.133)" fill={color} />
          <circle cx="0.5" cy="0.5" r="0.5" transform="translate(1206 102.133)" fill={color} />
          <circle cx="0.5" cy="0.5" r="0.5" transform="translate(1204 102.133)" fill={color} />
        </g>
        <line
          x1="16.478"
          transform="translate(0.262 5.645)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
