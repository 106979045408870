import AbstractReducer from '../reducer';

export class Reducer extends AbstractReducer {
  constructor({ actioner, initialState = { value: null, loading: false } }) {
    super({ initialState });
    this._actioner = actioner;
  }

  reduce(state = null, action) {
    if (state === null) state = this.initialState;
    switch (action.type) {
      case this._actioner.actionTypes.SET:
        return { ...state, value: action.payload, loading: false };
      case this._actioner.actionTypes.SET_LOADING:
        return { ...state, loading: action.payload };
      default:
        return state;
    }
  }
}
