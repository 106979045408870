import React from 'react';
import { colors } from '@karnott/colors';

export const MiscellaneousIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(-735.777 -1123.608)">
      <g>
        <circle
          cx="1.857"
          cy="1.857"
          r="1.857"
          transform="translate(747.777 1135.608)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.857"
          cy="1.857"
          r="1.857"
          transform="translate(764.833 1135.608)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.857"
          cy="1.857"
          r="1.857"
          transform="translate(756.305 1135.608)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <g>
        <circle
          cx="1.857"
          cy="1.857"
          r="1.857"
          transform="translate(747.777 1144.136)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.857"
          cy="1.857"
          r="1.857"
          transform="translate(764.833 1144.136)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.857"
          cy="1.857"
          r="1.857"
          transform="translate(756.305 1144.136)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <g>
        <circle
          cx="1.857"
          cy="1.857"
          r="1.857"
          transform="translate(747.777 1152.664)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.857"
          cy="1.857"
          r="1.857"
          transform="translate(764.833 1152.664)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="1.857"
          cy="1.857"
          r="1.857"
          transform="translate(756.305 1152.664)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
