import Actioner from './actioner';
import { Api } from './api';
import { Reducer } from './reducer';
import { Selectors } from './selectors';

export default class Service {
  constructor({
    name,
    prefix,
    accessorChain,
    apiBase,
    apiPath,
    authenticated,
    initialState,
    serializeValue,
    defaultQuery,
    defaultParams,
  }) {
    this.name = name;
    this.api = new Api({ base: apiBase, root: apiPath, authenticated, defaultQuery, defaultParams });
    this.actioner = new Actioner({ prefix, api: this.api });
    this.reducer = new Reducer({ actioner: this.actioner, initialState });
    this.selectors = new Selectors({ accessorChain, serializeValue });
  }
}
