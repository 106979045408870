import React from 'react';
import { colors } from '@karnott/colors';

export const FiltersIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(-1231 -193.048)">
        <g transform="translate(1240 203)">
          <circle
            cx="1.199"
            cy="1.199"
            r="1.199"
            transform="translate(7.918)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <circle
            cx="1.199"
            cy="1.199"
            r="1.199"
            transform="translate(2.286 3.3)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <circle
            cx="1.199"
            cy="1.199"
            r="1.199"
            transform="translate(5.22 6.6)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="2.293"
            transform="translate(10.316 1.199)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="7.918"
            transform="translate(0 1.199)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="7.925"
            transform="translate(4.684 4.499)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="2.286"
            transform="translate(0 4.499)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g transform="translate(0.114 7.799)">
            <line
              x2="4.877"
              transform="translate(7.732)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line x2="5.106" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (17.897 / 12.949)} height={size} viewBox="0 0 17.897 12.949">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(-59.133 -1261.513)">
          <circle
            cx="1.592"
            cy="1.592"
            r="1.592"
            transform="translate(69.649 1261.513)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <circle
            cx="1.592"
            cy="1.592"
            r="1.592"
            transform="translate(62.169 1265.896)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <circle
            cx="1.592"
            cy="1.592"
            r="1.592"
            transform="translate(66.065 1270.279)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="3.045"
            transform="translate(72.833 1263.106)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="10.516"
            transform="translate(59.133 1263.106)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="10.525"
            transform="translate(65.353 1267.488)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x2="3.036"
            transform="translate(59.133 1267.488)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g>
            <line
              x2="6.477"
              transform="translate(69.553 1271.871)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="6.78"
              transform="translate(59.285 1271.871)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
