import { AbstractService } from '../abstract';
import Actioner from './actioner';
import Reducer from './reducer';
import Selectors from './selectors';
import {
  DEFAULT_TOKEN_TYPE,
  DEFAULT_ACCESSOR_CHAIN,
  DEFAULT_ACTION_PREFIX,
  DEFAULT_HEADER_KEY,
  DEFAULT_SERVICE_NAME,
} from './constants';
import AuthenticationManager from './manager';

export default class AuthenticationService extends AbstractService {
  constructor({
    serviceName = DEFAULT_SERVICE_NAME,
    actionPrefix = DEFAULT_ACTION_PREFIX,
    accessorChain = DEFAULT_ACCESSOR_CHAIN,
    authenticationHeaderKey = DEFAULT_HEADER_KEY,
    authenticationTokenType = DEFAULT_TOKEN_TYPE,
    oauthServerBaseUrl,
    clientId,
    redirectPath,
    storage = localStorage,
    onAuthExpired,
  }) {
    const actioner = new Actioner({ prefix: actionPrefix });
    const reducer = new Reducer({ actioner });
    const selectors = new Selectors({ accessorChain });
    super({
      name: serviceName,
      actioner,
      reducer,
      selectors,
    });

    this.authenticationManager = new AuthenticationManager({
      authenticationHeaderKey,
      authenticationTokenType,
      oauthServerBaseUrl,
      clientId,
      redirectPath,
      storage,
      onAuthExpired,
    });

    this.reducer.setInitialState(this.authenticationManager.credentials);
  }
}
