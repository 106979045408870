import React from 'react';
import { colors } from '@karnott/colors';

export const InterventionDetailsIcon = ({
  color = '#000',
  size = 30,
  backgroundColor = colors('grey'),
  circled = false,
}) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle data-name="Ellipse 391" cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(10 9)">
        <path
          d="M189.655,1279.835h-1.4a.966.966,0,0,0-.924,1v9.883a.966.966,0,0,0,.924,1h8.82a.967.967,0,0,0,.924-1v-9.883a.967.967,0,0,0-.924-1h-1.109"
          transform="translate(-187.336 -1278.805)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M195.924,1279.715h0a1.033,1.033,0,0,0-1.045-1.016h-3.932a1.032,1.032,0,0,0-1.045,1.016h0v.033h0v1.179h6.03v-.033h0Z"
          transform="translate(-187.575 -1278.699)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (11.667 / 13.911)} height={size} viewBox="0 0 11.667 13.911">
      <g transform="translate(0.5 0.5)">
        <path
          d="M189.655,1279.835h-1.4a.966.966,0,0,0-.924,1v9.883a.966.966,0,0,0,.924,1h8.82a.967.967,0,0,0,.924-1v-9.883a.967.967,0,0,0-.924-1h-1.109"
          transform="translate(-187.336 -1278.805)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M195.924,1279.715h0a1.033,1.033,0,0,0-1.045-1.016h-3.932a1.032,1.032,0,0,0-1.045,1.016h0v.033h0v1.179h6.03v-.033h0Z"
          transform="translate(-187.575 -1278.699)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
