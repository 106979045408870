import React from 'react';
import { colors } from '@karnott/colors';

export const ChartViewIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(-384.145 -1252.97)">
        <g>
          <g>
            <path
              d="M401.995,1266.275h3.021a4.321,4.321,0,0,0-4.336-4.305v4.305h1.315"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M401.375,1271.649a4.175,4.175,0,0,0,1.241-2.973H398.38"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M398.38,1268.676v-4.205a4.205,4.205,0,1,0,2.995,7.178"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (11.871 / 11.911)} height={size} viewBox="0 0 11.871 11.911">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(-394.145 -1261.97)">
          <g>
            <path
              d="M401.995,1266.275h3.021a4.321,4.321,0,0,0-4.336-4.305v4.305h1.315"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M401.375,1271.649a4.175,4.175,0,0,0,1.241-2.973H398.38"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M398.38,1268.676v-4.205a4.205,4.205,0,1,0,2.995,7.178"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
