import AbstractApi from '../api';

export class Api extends AbstractApi {
  constructor({ base, root, authenticated, defaultQuery = {}, defaultParams = {} }) {
    super({ base, root, authenticated });
    this.defaultQuery = defaultQuery;
    this.defaultParams = defaultParams;
  }

  read(params, query) {
    return this.fetchAPI(
      '',
      { method: 'GET', 'content-type': 'application/json', ...(params || this.defaultParams) },
      query || this.defaultQuery,
    );
  }
}
