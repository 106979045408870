import { useCallback, useState } from 'react';

function useInputFocus({ onFocus, onBlur }) {
  const [focused, setFocused] = useState(false);
  const focusCB = useCallback(() => {
    onFocus && onFocus();
    setFocused(true);
  }, [onFocus]);
  const blurCB = useCallback(() => {
    onBlur && onBlur();
    setFocused(false);
  }, [onBlur]);

  return [focused, focusCB, blurCB];
}

function useInputChangeCallback(callback) {
  return useCallback(
    e => {
      return callback(e && e.target ? e.target.value : '');
    },
    [callback],
  );
}

function useInputValue(initialValue) {
  const [value, setValue] = useState(initialValue);
  const onInputChangeCB = useInputChangeCallback(setValue);

  return [value, onInputChangeCB];
}

export const Effects = {
  useInputFocus,
  useInputValue,
  useInputChangeCallback,
};
