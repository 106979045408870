import React from 'react';
import { colors } from '@karnott/colors';

export const DriverIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle data-name="Ellipse 391" cx="15" cy="15" r="15" fill={backgroundColor} />

      <g transform="translate(9 9)">
        <g transform="translate(0 5.044)">
          <path
            d="M24.47,117.862a6.867,6.867,0,0,1-.223,1.552,3.017,3.017,0,0,1-.641,1.032,2.874,2.874,0,0,1-.993.671,3.571,3.571,0,0,1-2.567,0,2.862,2.862,0,0,1-.995-.671,2.984,2.984,0,0,1-.641-1.032,6.81,6.81,0,0,1-.224-1.569"
            transform="translate(-15.175 -117.845)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g transform="translate(0 3.12)">
            <path
              d="M23.283,120.643a6.427,6.427,0,0,1,1.045.3,5.648,5.648,0,0,1,1.964,1.253,5.472,5.472,0,0,1,1.216,1.942,5.214,5.214,0,0,1,.277.984h-12.3a4.967,4.967,0,0,1,.272-.984,5.5,5.5,0,0,1,1.233-1.94,5.566,5.566,0,0,1,1.948-1.253,6.187,6.187,0,0,1,.82-.253"
              transform="translate(-15.486 -120.643)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <g transform="translate(1.67)">
          <path
            d="M25.028,116.592a.756.756,0,0,1,.354.573c0,.764-1.77,1.383-3.953,1.383s-3.953-.619-3.953-1.383a.706.706,0,0,1,.289-.521"
            transform="translate(-16.927 -112.945)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g transform="translate(0 0)">
            <path
              d="M21.462,113.322a7.056,7.056,0,0,0-3.872,1.611c-.882.665-.79,1.468.2,2.008l.022.011a.114.114,0,0,0,.057.015h7.184a.119.119,0,0,0,.059-.015l.036-.019c.98-.541,1.066-1.338.19-2A7.011,7.011,0,0,0,21.462,113.322Z"
              transform="translate(-16.983 -113.322)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <g transform="translate(0 0)">
            <path
              d="M21.462,113.322a7.046,7.046,0,0,0-3.872,1.613c-.882.665-.79,1.467.2,2.006l.021.012a.119.119,0,0,0,.059.015h7.183a.119.119,0,0,0,.059-.015l.022-.012c.988-.54,1.083-1.342.2-2.008A7.01,7.01,0,0,0,21.462,113.322Z"
              transform="translate(-16.983 -113.322)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (13.298 / 13.645)} height={size} viewBox="0 0 13.298 13.645">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(0 5.044)">
          <path
            d="M24.47,117.862a6.867,6.867,0,0,1-.223,1.552,3.017,3.017,0,0,1-.641,1.032,2.874,2.874,0,0,1-.993.671,3.571,3.571,0,0,1-2.567,0,2.862,2.862,0,0,1-.995-.671,2.984,2.984,0,0,1-.641-1.032,6.81,6.81,0,0,1-.224-1.569"
            transform="translate(-15.175 -117.845)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g transform="translate(0 3.12)">
            <path
              d="M23.283,120.643a6.427,6.427,0,0,1,1.045.3,5.648,5.648,0,0,1,1.964,1.253,5.472,5.472,0,0,1,1.216,1.942,5.214,5.214,0,0,1,.277.984h-12.3a4.967,4.967,0,0,1,.272-.984,5.5,5.5,0,0,1,1.233-1.94,5.566,5.566,0,0,1,1.948-1.253,6.187,6.187,0,0,1,.82-.253"
              transform="translate(-15.486 -120.643)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <g transform="translate(1.67)">
          <path
            d="M25.028,116.592a.756.756,0,0,1,.354.573c0,.764-1.77,1.383-3.953,1.383s-3.953-.619-3.953-1.383a.706.706,0,0,1,.289-.521"
            transform="translate(-16.927 -112.945)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g transform="translate(0 0)">
            <path
              d="M21.462,113.322a7.056,7.056,0,0,0-3.872,1.611c-.882.665-.79,1.468.2,2.008l.022.011a.114.114,0,0,0,.057.015h7.184a.119.119,0,0,0,.059-.015l.036-.019c.98-.541,1.066-1.338.19-2A7.011,7.011,0,0,0,21.462,113.322Z"
              transform="translate(-16.983 -113.322)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <g transform="translate(0 0)">
            <path
              d="M21.462,113.322a7.046,7.046,0,0,0-3.872,1.613c-.882.665-.79,1.467.2,2.006l.021.012a.119.119,0,0,0,.059.015h7.183a.119.119,0,0,0,.059-.015l.022-.012c.988-.54,1.083-1.342.2-2.008A7.01,7.01,0,0,0,21.462,113.322Z"
              transform="translate(-16.983 -113.322)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
