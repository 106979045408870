import React from 'react';
import { colors } from '@karnott/colors';

export const PlayerIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(12 9)">
        <path
          d="M17.829,14.847l7.018,5.74a.532.532,0,0,1,0,.826l-7.018,5.74a.534.534,0,0,1-.872-.414V15.261A.534.534,0,0,1,17.829,14.847Z"
          transform="translate(-16.957 -14.725)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (9.086 / 13.574)} height={size} viewBox="0 0 9.086 13.574">
      <g transform="translate(0.5 0.512)">
        <path
          d="M17.829,14.847l7.018,5.74a.532.532,0,0,1,0,.826l-7.018,5.74a.534.534,0,0,1-.872-.414V15.261A.534.534,0,0,1,17.829,14.847Z"
          transform="translate(-16.957 -14.725)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
