import { colors } from '@karnott/colors';
import { Toggle } from '@karnott/form';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: ${fontFamily()};
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: ${pixelSize('small')};
  color: ${({ active }) => colors('grey', active ? 700 : 500)};
`;

const Name = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: ${pixelSpacing('small')};
`;

export function TracksFilter({ filters, toggleTrackVisibilty, descriptions }) {
  const seriesLabels = useMemo(() => {
    return descriptions.map(({ label }) => label);
  }, [descriptions]);

  const seriesColors = useMemo(() => {
    return descriptions.map(({ color }) => color);
  }, [descriptions]);

  return (
    <Container>
      <Labels>
        {seriesLabels.map((description, id) => {
          return (
            <Label
              active={filters && !filters[id]}
              key={description}
              onClick={e => {
                e.stopPropagation();
                toggleTrackVisibilty(id);
              }}
            >
              <Toggle
                color={seriesColors[id]}
                value={filters && !filters[id]}
                toggle={() => toggleTrackVisibilty(id)}
              />
              <Name>{description}</Name>
            </Label>
          );
        })}
      </Labels>
    </Container>
  );
}

export { TracksFilterHooks } from './effects';
