class KarnottException extends Error {
  constructor({ message, code }) {
    super(message);
  }
}

class AbstractReduceFunctionOverride extends KarnottException {
  constructor() {
    super({ message: 'Reduce function must be overriden' });
  }
}

class AbstractActionerNoActionTypes extends KarnottException {
  constructor() {
    super({ message: 'You have created an Actioner instance without any action type' });
  }
}

class AbstractActionerBuildMutationTypeNotInTypes extends KarnottException {
  constructor() {
    super({ message: 'The action type provided is not one of the service' });
  }
}

export const Exceptions = {
  KarnottException,
  AbstractReduceFunctionOverride,
  AbstractActionerNoActionTypes,
  AbstractActionerBuildMutationTypeNotInTypes,
};
