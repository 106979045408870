import React from 'react';
import { colors } from '@karnott/colors';

export const PlotsIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) =>
  circled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill={backgroundColor} />
      <g transform="translate(6 7)">
        <path
          d="M10.625,1267.431,8.171,1269.1l8.918,5.083,8.918-5.083-2.455-1.674"
          transform="translate(-8.171 -1258.673)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M17.089,1261l-8.918,6.083,8.918,6.083,8.918-6.083L17.089,1261Z"
          transform="translate(-8.171 -1261)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * (16.837 / 14.514)} height={size} viewBox="0 0 16.837 14.514">
      <g transform="translate(0.5 0.5)">
        <path
          d="M10.35,1267.431,8.171,1269.1l7.918,5.083,7.918-5.083-2.179-1.674"
          transform="translate(-8.171 -1260.673)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M16.089,1261l-7.918,5.083,7.918,5.083,7.918-5.083L16.089,1261Z"
          transform="translate(-8.171 -1261)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
