import React from 'react';
import { colors } from '@karnott/colors';

export const BaleIcon = ({ color = '#000', size = 44, backgroundColor = colors('grey'), circled = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44">
    {circled ? <circle id="Ellipse_75" data-name="Ellipse 75" cx="22" cy="22" r="22" fill={backgroundColor} /> : null}
    <g transform="translate(12 10)">
      <line
        x2="10.095"
        transform="translate(0.29 22.209)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <g>
        <path
          d="M132.008,1047.511a10.385,10.385,0,1,1,3.042-7.344,10.352,10.352,0,0,1-3.042,7.344"
          transform="translate(-114.28 -1028.343)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M128.041,1041.138a2.406,2.406,0,1,1-2.405-2.405A2.405,2.405,0,0,1,128.041,1041.138Z"
          transform="translate(-115.251 -1029.314)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M131.523,1040.656a6.37,6.37,0,1,1-6.37-6.37A6.369,6.369,0,0,1,131.523,1040.656Z"
          transform="translate(-114.769 -1028.832)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g transform="translate(8.946)">
          <line
            x1="1.439"
            y1="1.439"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            y1="1.439"
            x2="1.439"
            transform="translate(1.439)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(14.863 5.104)">
          <line y1="2.035" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <line
            x2="2.035"
            transform="translate(0 2.035)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(17.649 19.273)">
          <line
            x2="2.035"
            transform="translate(0)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line y2="2.035" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        </g>
      </g>
    </g>
  </svg>
);
